import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../images/logo.png';
import {connect} from "react-redux";
import { Avatar } from 'antd';
import history from '../communal/history';
import realmName from '../../json/realmName.json';
import $ from 'jquery';

const Div=styled.div`
    background: #000221;
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    height: 70px;
    z-index:999;
    .home-header{
        width: 1200px;
        margin:0 auto;
        line-height:70px; 
        .logo{
            display: inline-block;
            img{
                vertical-align:middle;
                height: 55px;
                margin-top:-20px;
                cursor: pointer;
            }
        }
        .home-nav{
            display: inline-block;
            margin-left:15px;
            a{
                color: #fff;
                display: inline-block;
                margin:0 40px;
                box-sizing:border-box;
                font-size:20px;
                position: relative;
                text-decoration: none;
            }
            .active{
                b{
                    display: inline-block;
                    height: 5px;
                    background:#7059EF;
                    border-radius:5px;
                    width: 100%;
                    position: absolute;
                    bottom:2px;
                    left:0px;
                }
            }
        }
        .header-right{
            float: right;
            .register-login{
                span{
                    background:#7059EF;
                    color: #fff;
                    padding: 8px 26px;
                    font-size: 18px;
                    border-radius: 20px;
                    cursor: pointer;
                }
            }
            .person-photo{
                cursor: pointer;
                position:relative;
                .drop-down{
                    position: absolute;
                    background:#fff;
                    width: 120px;
                    text-align:center;
                    border-radius:10px;
                    border:1px solid #ddd;
                    top:65px;
                    left:-31px;
                    z-index:999; 
                    line-height:35px;
                    padding:10px 0; 
                    display: none;
                    span{
                        display: inline-block;
                        width: 100%;
                        line-height:40px;
                        font-size:16px; 
                    }
                    span:hover{
                        color: #7059EF;
                    }
                }
            }
            .person-photo:hover .drop-down{
                display: block;
            }
        }
    }
`
class Header extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){

    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelHref(){
        history.push("/personal")
    }
    // sso登录
    handleSsoLogin(){
        let href =window.location.href.replace("#","@");
        window.location.href=window.location.protocol + "//" + window.location.host + "/client/?next="+href;
    }
    handelHome(){
        history.push("/")
    }
    //退出洞明
    handleLogoutYun(){
        // var that = this;
        $.ajax({
            url: "/api/user/logout/",
            type: "GET",
            success: function (data) {
                window.location.reload();
            }
        })
    }
    // 退出SSO
    handleLogout() {
        var that = this;
        this.handleLogoutYun()
        $.ajax({
            url: realmName.sso + "/server_app/logout/",
            type: "GET",
            data: { uuid: that.props.uuid },
            // dataType: "jsonp",    //跨域json请求一定是jsonp
            // jsonp: "callbackparam",    //跨域请求的参数名，默认是callback
            success: function (data) {

            }
        })
    }
    render(){
        const pathname=this.props.pathname;
        return <Div>
            <div className="home-header">
                <div className="logo">
                    <img onClick={this.handelHome.bind(this)} src={Logo} alt=""/>
                </div>
                <div className="home-nav">
                    <a
                        href="/#/"
                        className={pathname==="/"?"active":""}
                    >
                        首页
                        <b></b>
                    </a>
                    <NavLink to="/ecology" replace className={pathname.indexOf("/ecology")>-1?"active":""}>
                        社区生态
                        <b></b>
                    </NavLink>
                    <NavLink to="/matrix" replace className={pathname.indexOf("/matrix")>-1?"active":""}>
                        安全矩阵
                        <b></b>
                    </NavLink>
                    <NavLink to="/tools" replace className={pathname.indexOf("/tools")>-1?"active":""}>
                        社区资源
                        <b></b>
                    </NavLink>
                    <NavLink to="/shop" replace className={pathname.indexOf("/shop")>-1?"active":""}>
                        礼品兑换
                        <b></b>
                    </NavLink>
                </div>
                <div className="header-right">
                    {
                        !this.props.login&&
                        <div className="register-login">
                            <span onClick={this.handleSsoLogin.bind(this)}>注册/登录</span>
                        </div>
                    }
                    {
                        this.props.login&&
                        <div className="person-photo">
                            <Avatar src={this.props.info.photo} size={50}/> 
                            <div className="drop-down">
                                <span onClick={this.handelHref.bind(this)}>个人中心</span>
                                <span onClick={this.handleLogout.bind(this)}>退出</span>
                            </div>  
                        </div>
                    }
                </div>
            </div>
        </Div>
    }
}
const mapStateToProps = (state)=> {
     return {
        pathname:state.pathname,
        login:state.login,
        uuid:state.uuid,
        info:state.info
     }
};
export default connect(
    mapStateToProps,
)(Header);
