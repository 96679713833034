import {
    FetchClient,
    Interceptor
} from 'intercept-fetch';
import history from "./history";
import Local from './Local';
const Fetch = new FetchClient();
Fetch.setTimeout(60000);
function getCookie(name) {
    if (!document.cookie) {
        return null;
    }
    const csrfCookies = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));

    if (csrfCookies.length === 0) {
        return null;
    }
    return decodeURIComponent(csrfCookies[0].split('=')[1]);
}
const interceptor = new Interceptor({
    cors: {
        id: 0,
        request(url, config) {
            config.mode = 'cors';
            config.cache = 'no-cache';
            config.credentials = 'same-origin';
            if (config.method==="POST"){
                config.headers =Object.assign({},{
                    'Accept': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken')
                },config.headers?config.headers:{})
            }
            return Promise.resolve([url, config])
        },
        success(data) {
            return Promise.resolve(data);
        },
        error(res) {
            if (res.status === 403) {
                res.json().then((data) => {
                    if (data.detail === "未登录") {
                        Local.clear();
                        return history.push("/"); 
                    }else if(data.detail==="已经登录"){
                        return history.push("/");
                    }
                })
            }else if (res.status === 404) {
                
            }else{
                return Promise.resolve(res);
            }
        },
        timeout(url) {
            return Promise.resolve('请求超时');
        }
    }
})

Fetch.setInterceptors(interceptor);
export default Fetch;