import React, { Component } from 'react';
import styled from 'styled-components';
import Fetch from '../communal/Fetch';
import {Alert} from '../communal/utility';
const Div=styled.div`
    .Flow-Chart,.requisition{
        display: inline-block;
        vertical-align:top; 
    }
    .Flow-Chart{
        width: 35%;
        background: ${props=>{
            return "url("+props.img+") no-repeat";
        }};
        background-size:contain;
    }
    .requisition{
        width: 65%;
        padding-top:50px;
        overflow-x:hidden;
        overflow-y:auto;
        box-sizing:border-box;
        p{
            line-height:50px;
            font-size:30px;
            font-weight:bold; 
            text-align:center; 
        }
        .form{
            padding-bottom:30px;
            width: 80%;
            margin:0 auto;
            .single-input{
                margin-top:30px;
                .title{
                    display: inline-block;
                    vertical-align:top; 
                    text-align:right; 
                    width: 120px;
                    font-size:18px;
                    line-height:40px;
                }
                .inline-block{
                    display: inline-block;
                    vertical-align:top; 
                    width: calc(100% - 120px);
                    line-height:40px;
                    padding-left:20px; 
                    box-sizing:border-box;
                    font-size:14px;
                    input{
                        width: 100%;
                        height: 40px;
                        border:1px solid #ddd;
                        padding:0 10px;
                    }
                    textarea{
                        width: 100%;
                        height: 120px;
                        border:1px solid #ddd;
                        padding:5px 10px;
                        resize:none;
                    }
                    .code{
                        width: calc(100% - 180px);
                    }
                    span{
                        display: inline-block;
                        vertical-align:top; 
                        background:#7059EF;
                        height: 40px;
                        width: 120px;
                        text-align:center;
                        color:#fff;
                        font-size:16px;
                        border-radius:5px;
                        cursor: pointer;
                        margin-left:20px; 
                    }
                    img{
                        vertical-align:top;
                        height: 40px;
                        cursor: pointer; 
                    }
                }
            }
        }
    }
    .submit-btn{
        text-align:center;
        margin-top:50px;
        span{
            display: inline-block;
            line-height:50px;
            color:#fff;
            font-size:18px;
            background:#7059EF;
            width: 80%;
            border-radius:15px;
            cursor: pointer;
        } 
    }
`
class Apply extends Component{
    constructor(props){
        super(props);
        this.state={
            FlowChart:{},
            submit:{
                company:"",
                product_name:"",
                product_desc:"",
                contacts:"",
                phone:"",
                verify_code:"",
                code:""
            },
            error:{
                company:[],
                product_name:[],
                product_desc:[],
                contacts:[],
                phone:[],
                verify_code:[],
                code:[] 
            },
            graph_code:"api/sec_code/captcha/?"+new Date().getTime(),
            time_left:0,
        }
    }
    componentDidMount(){
        document.getElementsByClassName("Flow-Chart")[0].style.height=document.body.clientHeight-5+"px";
        document.getElementsByClassName("requisition")[0].style.height=document.body.clientHeight-5+"px";
        Fetch.get("/api/images/?page=4&location=4").then(data=>{
            if(data.length>0){
                this.setState({
                    FlowChart:data[0]
                })
            }
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    //切换验证码
    handelClickCode(){
        this.setState({
            graph_code:"api/sec_code/captcha/?"+new Date().getTime()
        })
    }
    //input框change事件
    handelChange(name,event){
        let submit=this.state.submit 
        submit[name]=event.target.value;
        this.setState({
            submit:submit,
        })
    }
    handelFouce(name){
        let error=this.state.error;
        error[name]=[];
        this.setState({
            error:error
        })
    }
    //获取手机验证码
    handeSecode(){
        let submit=this.state.submit;
        if(submit.phone===""){
            Alert("error","请您输入正确的手机号码")
            return
        }else if(submit.code===""){
            Alert("error","请您输入正确的图形验证码")
            return
        }
        let list={
            phone:submit.phone,
            picture_code:submit.code
        }
        Fetch.post("/api/sec_code/verify_code/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            Alert("success",data.msg);
            this.setState({
                time_left:data.time_left
            },()=>{
                this.getTimeDown();
            })
        }).catch(err=>{
            Alert("error",err.msg);
            this.handelClickCode();
        })
    }
    //获取验证码倒计时
    getTimeDown(){
        this.timer=setInterval(()=>{
            let time_left=this.state.time_left;
            if(time_left>0){
                time_left--
                this.setState({
                    time_left:time_left
                })
            }else{
                clearInterval(this.timer);
            }
        },1000)
    }
    //入驻申请
    applyFor(){
        let submit=this.state.submit;
        let error=this.state.error;
        if(submit.company===""){
            error.company=["公司名称不能为空"];
        }
        if(submit.product_name===""){
            error.product_name=["产品/服务名称不能为空"];
        }
        if(submit.product_desc===""){
            error.product_desc=["产品/服务简述不能为空"];
        }
        if(submit.contacts===""){
            error.contacts=["联系人不能为空"];
        }
        if(submit.phone===""){
            error.phone=["手机号不能为空"];
        }
        if(submit.verify_code===""){
            error.verify_code=["手机验证码不能为空"];
        }
        if(
            submit.company===""||
            submit.product_name===""||
            submit.product_desc===""||
            submit.contacts===""||
            submit.phone===""||
            submit.verify_code===""
        ){
            this.setState({
                error:error
            })
            return
        }
        let list={
            company:submit.company,
            product_name:submit.product_name,
            product_desc:submit.product_desc,
            contacts:submit.contacts,
            phone:submit.phone,
            verify_code:submit.verify_code
        }
        Fetch.post("/api/products/enter_applies/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            Alert("success","入驻申请发送成功")
        }).catch(err=>{
            if(err.company){
                error.company=err.company
            }
            if(err.product_name){
                error.product_name=err.product_name
            }
            if(err.product_desc){
                error.product_desc=err.product_desc
            }
            if(err.contacts){
                error.contacts=err.contacts
            }
            if(err.phone){
                error.phone=err.phone
            }
            if(err.verify_code){
                error.verify_code=err.verify_code
            }
            if(err.msg){
                Alert("error",err.msg)
            }
            if(err.non_field_errors){
                Alert("error",err.non_field_errors)
            }
            this.setState({
                error:error
            })
        })
    }
    render(){
        const FlowChart=this.state.FlowChart;
        const submit=this.state.submit;
        const error=this.state.error;
        return <Div img={FlowChart.path}>
            <div className="Flow-Chart">

            </div>
            <div className="requisition">
                <p>入驻申请</p>
                <div className="form">
                    <div className="single-input">
                        <span className="title">公司名称</span>
                        <div className="inline-block">
                            <input type="text" value={submit.company} onFocus={this.handelFouce.bind(this,"company")} onChange={this.handelChange.bind(this,"company")} placeholder="请输入您的公司名称"/>
                            {
                                error.company.length>0&&
                                <div className="error-info">{error.company[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">产品/服务名称</span>
                        <div className="inline-block">
                            <input type="text" value={submit.product_name} onFocus={this.handelFouce.bind(this,"product_name")} onChange={this.handelChange.bind(this,"product_name")} placeholder="请输入您要申请入驻的产品/服务名称"/>
                            {
                                error.product_name.length>0&&
                                <div className="error-info">{error.product_name[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">产品/服务简述</span>
                        <div className="inline-block">
                            <textarea  value={submit.product_desc} onFocus={this.handelFouce.bind(this,"product_desc")} onChange={this.handelChange.bind(this,"product_desc")} placeholder="请对您要申请入驻的产品/服务进行简单功能描述"></textarea>
                            {
                                error.product_desc.length>0&&
                                <div className="error-info">{error.product_desc[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">联系人</span>
                        <div className="inline-block">
                            <input type="text" value={submit.contacts} onFocus={this.handelFouce.bind(this,"contacts")} onChange={this.handelChange.bind(this,"contacts")}  placeholder="请输入申请人姓名"/>
                            {
                                error.contacts.length>0&&
                                <div className="error-info">{error.contacts[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">验证码</span>
                        <div className="inline-block">
                            <input className="code" value={submit.code} onFocus={this.handelFouce.bind(this,"code")} onChange={this.handelChange.bind(this,"code")}  type="text" placeholder="请输入验证码"/>
                            <img onClick={this.handelClickCode.bind(this)} src={this.state.graph_code} alt=""/>
                            {
                                error.code.length>0&&
                                <div className="error-info">{error.code[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">手机号</span>
                        <div className="inline-block">
                            <input type="number" value={submit.phone} onFocus={this.handelFouce.bind(this,"phone")} onChange={this.handelChange.bind(this,"phone")} placeholder="请输入您的手机号"/>
                            {
                                error.phone.length>0&&
                                <div className="error-info">{error.phone[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">手机验证码</span>
                        <div className="inline-block">
                            <input className="code" onFocus={this.handelFouce.bind(this,"verify_code")} value={submit.verify_code} onChange={this.handelChange.bind(this,"verify_code")} type="number" placeholder="请输入获取的手机验证码"/>
                            
                            {
                                this.state.time_left===0?
                                <span onClick={this.handeSecode.bind(this)}>获取验证码</span>
                                :<span style={{background:"#666",cursor:"not-allowed"}}>{this.state.time_left}S</span>
                            }
                            {
                                error.verify_code.length>0&&
                                <div className="error-info">{error.verify_code[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="submit-btn">
                        <span onClick={this.applyFor.bind(this)}>提交申请</span>
                    </div>
                </div>
            </div>
        </Div>
    }
}
export default Apply;
