import React, { Component } from 'react';
import styled from 'styled-components';
import Carousel from './carousel';
import Filtrate from './filtrate'
import List from './list';
const Div=styled.div.attrs({className:"submodule"})`
    .set-width{
        width: 1200px;
        margin: 0 auto;
    }
`
class Tools extends Component{
    constructor(props){
        super(props);
        this.state={
            screen:{
                res_id:"",
                sec_id:"",
                search:"",
            } 
        }
    }
    componentDidMount(){
        document.title="社区资源";
        document.body.style.background="#F4F4F4";
    }
    componentWillUnmount(){
        document.body.style.background="#fff";
        this.setState=(state,callback)=>{
            return
        }
    }
    setScreen(type,id){
        let screen=this.state.screen;
        if(type===1){
            screen.res_id=id
        }else if(type===2){
            screen.sec_id=id
        }
        this.setState({
            screen:screen
        })
    }
    inputChange(value){
        let screen=this.state.screen;
        screen.search=value;
        this.setState({
            screen:screen
        })
    }
    render(){
        return <Div>
            {/* 轮播图 */}
            <div>
                <Carousel/>
            </div>
            {/* 筛选条件 */}
            <div className="set-width">
                <Filtrate 
                    setScreen={this.setScreen.bind(this)} 
                    change={this.inputChange.bind(this)}
                    screen={this.state.screen}
                />
            </div>
            {/* 工具列表 */}
            <div className="set-width">
                <List 
                    screen={this.state.screen}
                    key={JSON.stringify(this.state.screen)}
                />
            </div>
        </Div>
    }
}
export default Tools