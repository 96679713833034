import React, { Component } from 'react';
import styled from 'styled-components';
import Page from '../communal/Page';
import Hot from '../../images/hot.png';
import Fetch from '../communal/Fetch';
const Div=styled.div`
    .single-product{
        margin-top:20px;
        display: inline-block;
        position: relative;
        cursor: pointer;
        width:590px;
        height:150px;
        .product-img{
            width:100%;
            height:100%;
        }
        .product-hot{
            position: absolute;
            top:5px;
            right:7px;
        }
        .product-detail{
            position: absolute;
            bottom:18px;
            right:5px;
            text-align:right;
            color:#919191;
            font-size:14px;
            line-height:20px; 
            .company{
                color:#fff;
            }
        }
    }
    .single-product:nth-child(odd){
        margin-right:20px;
    }
    
`
class Product extends Component{
    constructor(props){
        super(props);
        this.state={
            list:[],
            filter:{
                size:10,
                page:1
            },
            allCount:0
        }
    }
    componentDidMount(){
        this.getProduct()
    }
    getProduct(){
        let screen=this.props.screen;
        let filter=this.state.filter;
        let str="?page="+filter.page+"&size="+filter.size;
        if(screen.sec_id!==""){
            str+="&sec_id="+screen.sec_id
        }
        if(screen.indus_id!==""){
            str+="&indus_id="+screen.indus_id
        }
        if(screen.search!==""){
            str+="&search="+screen.search
        }
        Fetch.get("/api/products/"+str).then(data=>{
            this.setState({
                allCount:data.count,
                list:data.results
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelDetail(id){
        window.open("/#/matrix/detail/"+id)
    }
    getPages(page){
        let filter=this.state.filter;
        filter.page=page;
        this.setState({
            filter:filter
        },()=>{
            this.getProduct()
        })
    }
    render(){
        const list=this.state.list;
        return <Div>
            {
                list.map((val,key)=>{
                    return <div key={key+"key"} 
                        className="single-product"
                        onClick={this.handelDetail.bind(this,val.id)}
                     >
                        <img className="product-img" src={val.summary_img} alt=""/>
                        <div className="product-hot">
                            {
                                val.top<100&&
                                <img src={Hot} alt=""/>
                            }
                        </div>
                        <div className="product-detail">
                            <div className="company">{val.manufacturer}</div>
                            <div>访问量：{val.page_view}</div>
                        </div>
                    </div>
                })
            }
            {
                list.length===0&&
                <div className="not-data">暂未筛选到数据</div>
            }
            <Page
                key="page"
                upDown
                pageNumber
                allNumber
                // jumpNumber
                // selectNumber
                nowpage={this.state.filter.page} //当前页
                allPage={this.state.allCount}
                everyPage={this.state.filter.size}
                onClick={this.getPages.bind(this)}
                onChange={this.getPages.bind(this)}
            />
        </Div>
    }
}
export default Product;