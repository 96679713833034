import React, { Component } from 'react';
import styled from 'styled-components';
import Close from '../../images/close.png';
import Fetch from '../communal/Fetch';
import {Alert} from '../communal/utility';

const Div=styled.div`
    background: rgba(0,0,0,.5);
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    z-index:99999;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    .edit-address-box{
        width: 800px;
        min-height:300px;
        background:#fff;
        padding-bottom:30px;
        .header{
            background:#E6E6E6;
            line-height:40px; 
            font-size:16px;
            padding:0 20px;
            font-weight:bold; 
            img{
                float: right;
                margin-top:10px;
                cursor: pointer;
            }
        }
        .content{
            width: 60%;
            margin:0 auto;
            .single-input{
                margin-top:20px;
                .title{
                    display: inline-block;
                    width: 100px;
                    text-align:right; 
                    line-height:40px; 
                    vertical-align:top; 
                    b{
                        color:#ffa39e;
                        display: inline-block;
                        vertical-align:middle;
                        margin-top:3px;
                        margin-right:5px; 
                    }
                }
                .block{
                    line-height:40px; 
                    display: inline-block;
                    vertical-align:top;
                    width: calc(100% - 100px);
                    box-sizing:border-box;
                    padding-left:20px;
                    input{
                        width: 100%;
                        height: 40px;
                        border:1px solid #ddd;
                        padding:0 10px;
                    }
                    textarea{
                        width: 100%;
                        height: 120px;
                        border:1px solid #ddd;
                        padding:5px 10px;
                        resize:none;
                    }
                }
            }
        }
    }
    .submit-btn{
        width: 60%;
        margin:0 auto;
        padding:0 120px;
        box-sizing:border-box;
        margin-top:20px;
        span{
            background:#7059EF;
            color:#fff;
            line-height:30px;
            width: 80px;
            text-align:center;
            display: inline-block; 
            cursor: pointer; 
        }
    }
`
class Address extends Component{
    constructor(props){
        super(props);
        this.state={
            submit:{
                brief:"",//概要地址
                details:"",//详细地址
                postcode:"",//邮政编码
                cosignee:"",//收货人姓名
                phone:"",//收货人电话
            },
            error:{
                brief:[],//概要地址
                details:[],//详细地址
                postcode:[],//邮政编码
                cosignee:[],//收货人姓名
                phone:[],//收货人电话
            }
        }
    }
    componentDidMount(){
        Fetch.get("/api/user/address/info/").then(data=>{
            this.setState({
                submit:data
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelChange(name,event){
        let submit=this.state.submit;
        submit[name]=event.target.value;
        this.setState({
            submit:submit
        })
    }
    handelFouce(name){
        let error=this.state.error;
        error[name]=[];
        this.setState({
            error:error
        })
    }
    handelSubmit(){
        let error=this.state.error;
        let submit=this.state.submit;
        if(submit.brief===""){
            error.brief=["地址信息不能为空"];
        }
        if(submit.details===""){
            error.details=["详细地址不能为空"];
        }
        if(submit.postcode===""){
            error.postcode=["邮政编码不能为空"];
        }
        if(submit.cosignee===""){
            error.cosignee=["收件人姓名不能为空"];
        }
        if(submit.phone===""){
            error.phone=["手机号码不能为空"];
        }
        if(
            submit.brief===""||
            submit.details===""||
            submit.postcode===""||
            submit.cosignee===""||
            submit.phone===""
        ){
            this.setState({
                error:error
            })
            return
        }
        let list={
            brief:submit.brief,
            details:submit.details,
            postcode:submit.postcode,
            cosignee:submit.cosignee,
            phone:submit.phone
        }
        Fetch.post("/api/user/address/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            this.props.close();
            Alert("success","地址保存成功")
        }).catch(err=>{
            if(err.brief){
                error.brief=err.brief
            }
            if(err.details){
                error.details=err.details
            }
            if(err.postcode){
                error.postcode=err.postcode
            }
            if(err.cosignee){
                error.cosignee=err.cosignee
            }
            if(err.phone){
                error.phone=err.phone
            }
            this.setState({
                error:error
            })
        })
    }
    render(){
        let error=this.state.error;
        let submit=this.state.submit;
        return <Div>
            <div className="edit-address-box">
                 <div className="header">
                    修改地址
                    <img onClick={this.props.close} src={Close} alt=""/>
                 </div>
                 <div className="content">
                    <div className="single-input">
                        <span className="title">
                            <b>*</b>
                            地址信息
                        </span>
                        <div className="block">
                            <input value={submit.brief} onChange={this.handelChange.bind(this,"brief")} onFocus={this.handelFouce.bind(this,"brief")} type="text" placeholder="请您输入地址信息"/>
                            {
                                error.brief.length>0&&
                                <div className="error-info">{error.brief[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">
                            <b>*</b>
                            详细地址
                        </span>
                        <div className="block">
                            <textarea value={submit.details} onChange={this.handelChange.bind(this,"details")} onFocus={this.handelFouce.bind(this,"details")} placeholder="请输入详细地址"></textarea>
                            {
                                error.details.length>0&&
                                <div className="error-info">{error.details[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">
                            <b>*</b>
                            邮政编码
                        </span>
                        <div className="block">
                            <input value={submit.postcode} onChange={this.handelChange.bind(this,"postcode")} onFocus={this.handelFouce.bind(this,"postcode")} type="number" placeholder="请您输入邮政编码"/>
                            {
                                error.postcode.length>0&&
                                <div className="error-info">{error.postcode[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">
                            <b>*</b>
                            收件人姓名
                        </span>
                        <div className="block">
                            <input type="text" value={submit.cosignee} onChange={this.handelChange.bind(this,"cosignee")} onFocus={this.handelFouce.bind(this,"cosignee")} placeholder="请您输入收件人姓名"/>
                            {
                                error.cosignee.length>0&&
                                <div className="error-info">{error.cosignee[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="single-input">
                        <span className="title">
                            <b>*</b>
                            手机号码
                        </span>
                        <div className="block">
                            <input type="number" value={submit.phone} onChange={this.handelChange.bind(this,"phone")} onFocus={this.handelFouce.bind(this,"phone")} placeholder="请您输入手机号码"/>
                            {
                                error.phone.length>0&&
                                <div className="error-info">{error.phone[0]}</div>
                            }
                        </div>
                    </div>
                 </div>
                <div className="submit-btn">
                    <span onClick={this.handelSubmit.bind(this)}>保存</span>
                </div>
            </div>
        </Div>
    }
}
export default Address;