import React, { Component } from 'react';
import styled from 'styled-components';
import history from '../communal/history';
import Fetch from '../communal/Fetch';
const Div=styled.div`
    background:#fff;
    margin-top:10px;
    .text-describe{
        line-height:50px;
        font-size:16px; 
        b{
            display: inline-block;
            height: 20px;
            width: 4px;
            vertical-align: middle;
            background:#7059EF;
            margin-left:10px;
            border-radius:3px;
            margin-right:13px;
        }
    }
`
class List extends Component{
    constructor(props){
        super(props);
        this.state={
            list:[]
        }
    }
    componentDidMount(){
        Fetch.get("/api/goods/?page=1&size=4&ordering=-saled_amount").then(data=>{
            this.setState({
                list:data.results,
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelDetail(id){
        history.push("/shop/detail/"+id)
    }
    render(){
        let list=this.state.list;
        return <Div>
            <div className="text-describe">
                <b></b>
                热门兑换排行
            </div>
            {
                list.map((val,key)=>{
                    return <div
                        key={"key"+key}
                        className="single-goods"
                        style={{margin:"0"}}
                    >
                        <div className="goods-photo" onClick={this.handelDetail.bind(this,val.id)}>
                            <img src={val.image} alt=""/>
                        </div> 
                        <div className="goods-name" title={val.name} onClick={this.handelDetail.bind(this,val.id)}>
                            {val.name}
                        </div>
                        <div className="goods-stat">
                            <span className="goods-price">
                                金币
                                <b>{val.price}</b>
                            </span>
                            <span className="count">
                                售出{val.saled_amount}/余量{val.left_amount}
                            </span>
                        </div>
                    </div>
                })
            }
            {
                list.length===0&&
                <div style={{fontSize:"20px"}} className="not-data">暂无热门兑换商品</div>
            }
        </Div>
    }
}
export default List;
