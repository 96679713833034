import React, { Component } from 'react';
import styled from 'styled-components';
import Address from './address';
import Fetch from '../communal/Fetch';
import {Alert} from '../communal/utility';
import history from '../communal/history';
const Div=styled.div.attrs({className:"submodule"})`
    padding-top:20px;
    padding-bottom:50px;
    .order-detail{
        width: 1200px;
        margin: 0 auto;
        background:#fff;
        .order-title{
            padding:0 10px;
            line-height:50px;
            font-size:20px;
            font-weight:bold;
            border-bottom:1px solid #ddd;
            b{
                display: inline-block;
                height: 25px;
                width: 4px;
                background:#7059EF;
                margin-right:10px;
                vertical-align:middle; 
                border-radius:3px;
            }  
            span{
                float: right;
                color:#7059EF
                font-size:15px;
                cursor: pointer;
            }
        }
        .shipping-address{
            margin:20px 10px;
            border:1px solid #7059EF;
            line-height:40px;
            background: #F0EEFD;
            font-size:14px;
            font-weight:bold; 
            i{
                color:#7059EF;
                font-size:25px;
                vertical-align:middle;
                margin:0 10px; 
            }
            span{
                color:#7059EF;
                margin-right:10px;
            }
        }
    }
    .order-table{
        margin:20px 50px;
        table{
            width: 100%;
            thead{
                text-align: center;
                tr{
                    border-bottom:2px solid #7059EF;
                    line-height:30px;
                    font-size:14px;
                    color:#666; 
                }
            }
            tbody{
                background:#F0EEFD;
                tr{
                    td{
                        text-align:center; 
                        padding:20px;
                        vertical-align: top;
                        img{
                            width: 30%;
                        }
                        span{
                            display: inline-block;
                            vertical-align:top; 
                            width: 70%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size:15px;
                            font-weight:bold; 
                        }
                    }
                    .price{
                        color:#ffa39e;
                        font-size:18px;
                    }
                }
            }
        }
    }
    .confirm-order{
        text-align:right;
        .order-box{
            margin:20px 50px
            padding:20px 15px;
            border:1px solid #7059EF;
            display: inline-block;
            margin-bottom:0px;
            .payment{
                line-height:50px;
                font-weight:bold;
                font-size:16px;
                span{
                    color:#ffa39e;
                    font-size:18px;
                    b{
                        font-size:25px;
                        display: inline-block;
                        margin-right:10px;
                    }
                }  
            }
           .profile{
               font-size:15px;
               line-height:30px; 
               span{
                   font-weight:bold;
                   display: inline-block;
                   margin-right:10px; 
                   font-size:16px;
               }
           } 
        }
        .confirm-order-btn{
            margin-right:50px;
            span{
                display: inline-block;
                background:#7059EF;
                cursor: pointer;
                color:#fff;
                width: 150px;
                line-height:50px; 
                text-align:center; 
                font-size:20px;
            }
        }
    }
`
class Order extends Component{
    constructor(props){
        super(props);
        this.state={
            editAddress:false,
            id:props.param.match.params.id,
            orderDetail:{},
            address:{
                brief:"",//概要地址
                details:"",//详细地址
                postcode:"",//邮政编码
                cosignee:"",//收货人姓名
                phone:"",//收货人电话
            },
        }
    }
    componentDidMount(){
        document.body.style.background="#F4F4F4";
        this.getOrder();
        this.getAddress();
    }
    //获取订单信息
    getOrder(){
        Fetch.get("/api/user/order/"+this.state.id+"/").then(data=>{
            this.setState({
                orderDetail:data
            })
        })
    }
    //获取地址信息
    getAddress(){
        Fetch.get("/api/user/address/info/").then(data=>{
            this.setState({
                address:data
            })
        })
    }
    componentWillUnmount(){
        document.body.style.background="#fff";
        this.setState=(state,callback)=>{
            return
        }
    }
    handelEdit(type){
        this.getAddress();
        this.setState({
            editAddress:type
        })
    }
    submitOrder(){
        var list={
            order_id:this.state.id
        }
        Fetch.post("/api/goods/pay/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            Alert("success","兑换成功，请到个人中心-我的消费查看信息");
            history.push("/shop");
        }).catch(err=>{
            Alert("error",err.msg);
        })
    }
    render(){
        const orderDetail=this.state.orderDetail;
        const address=this.state.address;
        return <Div>
            <div className="order-detail">
                <div className="order-address">
                    <div className="order-title">
                        <b></b>
                        确定收货地址
                        <span onClick={this.handelEdit.bind(this,true)}>修改收货地址</span>
                    </div>
                    <div className="shipping-address">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        <span>寄送至</span>
                        {
                            address.brief===""?
                            "暂未填写邮递地址":
                            address.brief+" "+address.details+"（"+address.cosignee  +" 收）"+ address.phone
                        }
                        
                    </div>  
                </div>
                <div className="order-message">
                    <div className="order-title">
                        <b></b>
                        确定订单信息
                    </div>
                    <div className="order-table">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width:"40%"}}>商品</th>
                                    <th  style={{width:"20%"}}>单价（金币）</th>
                                    <th  style={{width:"20%"}}>数量</th>
                                    <th  style={{width:"20%"}}>小计（金币）</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <img src={orderDetail.goods_image} alt=""/>
                                        <span>{orderDetail.goods_name}</span>
                                    </td>
                                    <td>
                                        <span>{orderDetail.price}</span>
                                    </td>
                                    <td>
                                        <span>{orderDetail.num}</span>
                                    </td>
                                    <td className="price">
                                        <span>{orderDetail.total_price}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="confirm-order">
                        <div className="order-box">
                            <div className="payment">
                                实付款：
                                <span>
                                    <b>{orderDetail.total_price}</b>
                                    金币
                                </span>
                            </div>
                            {
                                address.brief===""?
                                <div>暂未填写邮递地址</div>:[
                                <div className="profile" key="1">
                                    <span>寄送至</span>  {address.brief} {address.details}
                                </div>,
                                <div className="profile" key="2">
                                    <span>收货人</span> {address.cosignee}  {address.phone}
                                </div>
                                ]
                            }
                            
                        </div>
                        <div className="confirm-order-btn">
                            <span onClick={this.submitOrder.bind(this)}>
                                确认兑换
                            </span>
                        </div>
                    </div>
                </div>
            </div>
           {
               this.state.editAddress&&
               <Address close={this.handelEdit.bind(this,false)}/>
           }
        </Div>
    }
}
export default Order;