import React, { Component } from 'react';
import styled from 'styled-components';
import Page from '../communal/Page';
import history from '../communal/history';
import Fetch from '../communal/Fetch';
const Div=styled.div`

`
class List extends Component{
    constructor(props){
        super(props);
        this.state={
            list:[],
            screen:props.screen,
            filter:{
                page:1,
                size:9
            },
            all_count:0
        }
    }
    componentDidMount(){
        this.getGood()
    }
    //获取商品列表
    getGood(){
        let screen=this.state.screen;
        let filter=this.state.filter;
        let str="page="+filter.page+"&size="+filter.size+"&";
        if(screen.tag_id!==""){
            str+="tag_id="+screen.tag_id+"&"
        }
        if(screen.start_price!==""){
            str+="start_price="+screen.start_price+"&"
        }
        if(screen.end_price!==""){
            str+="end_price="+screen.end_price+"&"
        }
        if(screen.ordering!==""){
            str+="ordering="+screen.ordering+"&"
        }
        if(screen.owner!==""){
            str+="owner="+screen.owner+"&"
        }
        Fetch.get("/api/goods/?"+str).then(data=>{
            this.setState({
                list:data.results,
                all_count:data.count
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    getPages(page){
        let filter=this.state.filter;
        filter.page=page;
        this.setState({
            filter:filter
        },()=>{
            this.getGood()
        })
    }
    handelDetail(id){
        history.push("/shop/detail/"+id)
    }
    render(){
        const list=this.state.list;
        return <Div>
            {
                list.map((val,key)=>{
                    return <div
                        key={"key"+key}
                        className="single-goods"
                        style={(key+1)%3===0?{marginRight:"0",marginLeft:"0"}:{marginRight:"30px",marginLeft:"0"}}
                    >
                        <div className="goods-photo" onClick={this.handelDetail.bind(this,val.id)}>
                            <img src={val.image} alt=""/>
                        </div> 
                        <div className="goods-name" title={val.name} onClick={this.handelDetail.bind(this,val.id)}>
                            {val.name}
                        </div>
                        <div className="goods-stat">
                            <span className="goods-price">
                                金币
                                <b>{val.price}</b>
                            </span>
                            <span className="count">
                                售出{val.saled_amount}/余量{val.left_amount}
                            </span>
                        </div>
                    </div>
                })
            }
            {
                list.length===0&&
                <div className="not-data">暂未筛选到商品</div>
            }
            <Page
                key="page"
                upDown
                pageNumber
                allNumber
                // jumpNumber
                // selectNumber
                nowpage={this.state.filter.page} //当前页
                allPage={this.state.all_count}
                everyPage={this.state.filter.size}
                onClick={this.getPages.bind(this)}
                onChange={this.getPages.bind(this)}
            />
        </Div>
    }
}
export default List;
