import React, { Component } from 'react';
import {
	Router,
	Route,
	Switch
} from 'react-router-dom';
import history from '../communal/history';
import Info from './Info';
import Wallet from './Wallet';
import Consume from './consume';
import styled from 'styled-components';
import SideInfo from './sideinfo';

const Div=styled.div.attrs({className:"submodule"})`
    padding-top:50px;
    padding-bottom:50px;
    .personal-content{
        width: 1200px;
        margin:0 auto;
        border-radius:15px;
        box-shadow: 0px 1px 10px 0px rgba(112, 89, 239, 0.35);
        .personal-content-left,.personal-content-right{
            display: inline-block;
            vertical-align:top; 
        }
        .personal-content-left{
            width: 210px;
        }
        .personal-content-right{
            width: 990px;
        }
    }
`

class Index extends Component{
    componentDidMount(){
        document.title="个人中心";
    }
    render(){
        return <Div>
            <div className="personal-content">
                <div className="personal-content-left">
                    <SideInfo/>
                </div>
                <div className="personal-content-right">
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/personal" component={()=>{
                                return  <Info />
                            }}/>
                            <Route path="/personal/wallet" component={()=>{
                                return  <Wallet />
                            }}/>
                            <Route path="/personal/consume" component={()=>{
                                return  <Consume />
                            }}/>
                        </Switch>
                    </Router>
                </div>
            </div>
        </Div>
    }
}
export default Index