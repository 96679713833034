import React, { Component } from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';
import Attainment from '../../images/icon/attainment.png';
import Power from '../../images/icon/power.png';
import Contribution from '../../images/icon/contribution.png';
import Rank from '../../images/icon/rank.png';
import My from '../../images/icon/my.png';
import MyCheck from '../../images/icon/my_check.png';
import Wallet from '../../images/icon/wallet.png';
import WalletCheck from '../../images/icon/wallet_check.png';
import Consumer from '../../images/icon/consumer.png';
import ConsumerCheck from '../../images/icon/consumer_check.png'; 
import history from '../communal/history';
import {connect} from "react-redux";
import RecordPendant from '../../images/record.png'
import Fetch from '../communal/Fetch';
import realmName from '../../json/realmName.json'

const Div=styled.div`
    background:#f8f8f8;
    .info-header{
        padding:0 20px;
        text-align:center; 
        padding-top:20px;
        p{
            line-height:40px;
            font-size:16px; 
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom:0px;
        }
        .achievement{
            text-align:left;
            span{
                display: inline-block;
                vertical-align:top;
                width: 50%;
                line-height:40px;  
                font-size:14px;
                cursor: pointer;
                img{
                    vertical-align:middle;
                    margin-right:5px;
                    margin-top:-4px; 
                }
            } 
        }
    }
    .handover{
        margin-top:30px;
        span{
            display: inline-block;
            width: 100%;
            font-size:18px;
            position: relative;
            line-height:50px; 
            cursor: pointer;
            b{
                display: inline-block;
                position: absolute;
                width: 5px;
                height: 30px;
                left:0px;
                top: 10px;
                border-radius:5px;
            }
            img{
                vertical-align:middle;
                margin-top: -5px;
                margin-left: 40px;
                margin-right: 10px;
            }
        }
        .active{
            background:#fff;
            color:#7059EF;
            b{
                background:#7059EF;
            }
        }
    }
    .login-record{
        margin-top:100px;
        .header{
            line-height:40px;
            font-size:18px;
            border-bottom:1px solid #ddd;
            b{
                display: inline-block;
                width: 5px;
                height: 20px;
                background: #7059EF;
                margin-right:10px;
                margin-top: -2px;
                vertical-align: middle;
                border-radius:5px;
            } 
        
        }
        .record-content{
            margin-top:30px;
            background:#fff;
            border-radius:15px;
            position:relative;
            .pendant{
                position: absolute;
                top:-13px;
            }
            .pendant-left{
                left:25px;
            }
            .pendant-right{
                right:25px;
            }
        }
        .record-content-header{
            span{
                display: inline-block;
                width: 14.285%
                text-align:center;
                line-height:50px;
                font-size:15px;  
            }
        }
        .calendar-content{
            padding-bottom:30px;
            .single-day{
                display: inline-block;
                width: 14.285%
                text-align:center;
                height: 30px;
                font-size:14px; 
                position: relative;
                .text{
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius:50%;
                    vertical-align:middle; 
                }
                b{
                    position: absolute;
                    bottom:2px;
                    display: inline-block;
                    left:13px;
                    width: 5px;
                    height: 5px;
                    border-radius:50%;
                }
            }
            .before{
                color:#999;
            }
            .now{
                .text{
                    background:#7059EF;
                    color:#fff;
                }
            }
            .later{
                color:#000;
                font-weight:500; 
            }
            .login{
                color:#7059EF;
                b{
                    background:#7059EF;
                }
            }
        }
    }
`
class Info extends Component{
    constructor(props){
        super(props);
        this.state={
            model:1,//1代表我的资料 2 代表我的钱包 3 代表我的消费,
            calendar:[],
            record:[],
            achievement:{}
        }
    }
    componentDidMount(){
        this.loginRecord();
        Fetch.get("/api/user/achievements/").then(data=>{
            this.setState({
                achievement:data
            })
        })
    }
    //登录记录
    loginRecord(){
        Fetch.get("/api/user/login_records/").then(data=>{
            this.setState({
                record:data
            },()=>{
                this.getCalendar();
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    //计算日历
    getCalendar(){
        let record=this.state.record;
        //获取当前时间为几号
        var now = new Date();
        var date = now.getDate();
        //标记出当前时间之前和之后的样式并计算当前月第一天之前空的时间用 "" 代替
        var allDay=this.getCurrentMonthDays();
        var weekDay=this.getCurrentMonthFirstDay();
        let calendar=[];
        for(var i=1;i<allDay+1;i++){
            let count=0;
            for(var k=0;k<record.length;k++){
                if(i!==date&&i===record[k]){
                    count++
                    var log=i;
                    if(i<10){
                        log="0"+i
                    }
                    calendar.push({
                        date:log,
                        type:"login"
                    })
                }
            }
            if(count===0){
                if(i<date){
                    var day=i;
                    if(i<10){
                        day="0"+i
                    }
                    calendar.push({
                        date:day,
                        type:"before"
                    })
                }else if(i===date){
                    calendar.push({
                        date:"今",
                        type:"now"
                    })
                }else if(i>date){
                    var later=i;
                    if(i<10){
                        later="0"+i
                    }
                    calendar.push({
                        date:later,
                        type:"later"
                    })
                }
            }
        }
        for(var j=0;j<weekDay;j++){
            calendar.unshift({
                date:"",
                type:"before"
            })
        }
        this.setState({
            calendar:calendar
        })
    }
    //获取当前月的第一天是星期几
    getCurrentMonthFirstDay() {
        var date = new Date()
        date=new Date(date.setDate(1))
        var day = date.getDay()
        return day
    }
     //获取当前月总共有多少天
    getCurrentMonthDays(){
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var d = new Date(year, month, 0);
        return d.getDate();
    }
    handelToggle(type){
        switch(type){
            case 1:
                history.push("/personal");
            break
            case 2:
                history.push("/personal/wallet");
            break
            case 3:
                history.push("/personal/consume");
            break
            default:
                break
        }
        this.setState({
            model:type
        })
    }
    handelHref(type){
        if(type===1){
            window.open(realmName.yunyan)
        }else if(type===2){
            window.open(realmName.yanxing)
        }else if(type===3){
            window.open(realmName.tinypark)
        }
    }
    render(){
        let model=this.state.model;
        const calendar=this.state.calendar;
        const info=this.props.info;
        const achievement=this.state.achievement;
        switch(this.props.pathname){
            case "/personal":
                model=1;
                break
            case "/personal/wallet":
                model=2;
                break
            case "/personal/consume":
                model=3;
                break
            default:
                model=1;
                break
        }
        return <Div>
            <div className="info-header">
                <Avatar src={info.photo} size={100}/>
                <p>{info.username}</p> 
                <div className="achievement">
                    {/* 云演 */}
                    <span title="成长" onClick={this.handelHref.bind(this,1)}>
                        <img src={Attainment} alt=""/>
                        {
                            achievement.level!==undefined&&
                            {
                                0:"暂无",
                                1:"LV.1",
                                2:"LV.2",
                                3:"LV.3",
                                4:"LV.4",
                                5:"LV.5",
                                6:"LV.6",
                                7:"LV.7",
                                8:"LV.8",
                                9:"LV.9",
                            }[achievement.level.value]
                        }
                    </span>
                    {/* 雁行 */}
                    <span title="积分" onClick={this.handelHref.bind(this,2)}>
                        <img src={Rank} alt=""/>
                        {achievement.points!==undefined&&achievement.points.value}
                    </span>
                    {/* 论坛 */}
                    <span title="能量" onClick={this.handelHref.bind(this,3)}>
                        <img src={Power} alt=""/>
                        {achievement.ability!==undefined&&achievement.ability.value}
                    </span>
                    {/* 提交插件平台 */}
                    <span title="贡献" onClick={this.handelHref.bind(this,4)}>
                        <img src={Contribution} alt=""/>
                        {achievement.contribute!==undefined&&achievement.contribute.value}
                    </span>
                </div>
            </div>
            <div className="handover">
                <span className={model===1?"active":""} onClick={this.handelToggle.bind(this,1)}>
                    <b></b>
                    {
                        model===1?
                        <img src={MyCheck} alt=""/>
                        :<img src={My} alt=""/>
                    }
                    我的资料
                </span>
                <span className={model===2?"active":""} onClick={this.handelToggle.bind(this,2)}>
                    <b></b>
                    {
                        model===2?
                        <img src={WalletCheck} alt=""/>
                        :<img src={Wallet} alt=""/>
                    }
                    我的钱包
                </span>
                <span className={model===3?"active":""} onClick={this.handelToggle.bind(this,3)}>
                    <b></b>
                    {
                        model===3?
                        <img src={ConsumerCheck} alt=""/>
                        :<img src={Consumer} alt=""/>
                    }
                    我的消费
                </span>
            </div>
            <div className="login-record">
                <div className="header">
                    <b></b>
                    本月登录记录
                </div>
                <div className="record-content">
                    <img className="pendant pendant-left" src={RecordPendant} alt=""/>
                    <img className="pendant pendant-right" src={RecordPendant} alt=""/>
                    <div className="record-content-header">
                        <span>日</span>
                        <span>一</span>
                        <span>二</span>
                        <span>三</span>
                        <span>四</span>
                        <span>五</span>
                        <span>六</span>
                    </div>
                    <div className="calendar-content">
                        {
                            calendar.map((val,key)=>{
                                return <span
                                    key={"key"+key}
                                    className={val.type+" single-day"}
                                >
                                    <span className="text">{val.date}</span>
                                    <b></b>
                                </span>
                            })
                        }
                    </div>
                </div>
            </div>
        </Div>
    }
}
const mapStateToProps = (state)=> {
     return {
        pathname:state.pathname,
        info:state.info
     }
};
export default connect(
    mapStateToProps,
)(Info);