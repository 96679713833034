import React, { Component } from 'react';
import styled from 'styled-components';
import Fetch from '../communal/Fetch';
const Div=styled.div`
    background:#fff;
    padding:15px;
    margin:10px 0;
    .single-filter{
        margin:10px 0;
        .title{
            display: inline-block;
            vertical-align:top; 
            font-size:16px;
            font-weight:bold; 
            line-height:40px; 
        }
        .child-style{
            display: inline-block;
            vertical-align:top; 
            // height: 40px;
            span{
                display: inline-block;
                color: #333;
                vertical-align:middle; 
                padding: 5px 15px;
                border-radius: 18px;
                font-size: 14px;
                margin:0 5px;
                margin-top: 5px;
                cursor: pointer;
            }
            .active{
                background: #7059EF;
                color: #fff;
            }
        }
        .safe{
            width: 1080px;
        }
        .trade{
            width: 800px;
        }
        .search-model{
            float: right;
            margin-right:20px;
            margin-top: 2.5px;
            input{
                border:1px solid #7059EF;
                box-sizing:border-box;
                height: 35px; 
                padding:0 10px;
                width: 200px;
            }
            span{
                background: #7059EF;
                color:#fff;
                display: inline-block;
                line-height:35px;
                cursor: pointer;
                padding:0 10px;
                font-size:14px;
                vertical-align: top;
                margin-top: 0;
            }
        }
    }
`
class Filtrate extends Component{
    constructor(props){
        super(props);
        this.state={
            safeType:[],
            tradeType:[],
            screen:this.props.screen
        }
    }
    componentDidMount(){
       this.getResClass("");
    }
    getResClass(id){
        Fetch.get("/api/tools/res_classifies/").then(data=>{
            let classId=id;
            if(data.length>0){
                if(id===""){
                    classId=data[0].id;
                    data[0].check=true;
                }else{
                    for(var i=0;i<data.length;i++){
                        if(data[i].id===id){
                            data[i].check=true; 
                        }
                    }
                }
            }
            this.setState({
                safeType:data,
                classId:classId,
                sec_id:""
            },()=>{
                this.getSecClass()
            })
        })
    }
    getSecClass(){
        if(this.state.classId!==""){
            Fetch.get("/api/tools/res/"+this.state.classId+"/sec_classifies").then(res=>{
                let sec_id="";
                if(res.length>0){
                    if(this.state.sec_id===""){
                        sec_id=res[0].id;
                        res[0].check=true;
                    }else{
                        for(var i=0;i<res.length;i++){
                            if(res[i].id===this.state.sec_id){
                                res[i].check=true;
                            }
                        }
                    }
                    
                }
                this.setState({
                    tradeType:res,
                    sec_id:sec_id
                })
                this.props.setScreen(1,this.state.classId);
                this.props.setScreen(2,sec_id)
            })
        }else{
            this.props.setScreen(1,this.state.classId);
            this.props.setScreen(2,"")
        }
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelChange(type,index){
        let safeType=this.state.safeType;
        let tradeType=this.state.tradeType;
        let id="";
        if(type===1){
            for(var i=0;i<safeType.length;i++){
                safeType[i].check=false;
            }
            safeType[index].check=true;
            id=safeType[index].id;
            this.setState({
                classId:id
            },()=>{
                this.getResClass(id)
            })
        }else if(type===2){
            for(var j=0;j<tradeType.length;j++){
                tradeType[j].check=false;
            }
            tradeType[index].check=true;
            id=tradeType[index].id;
            this.setState({
                sec_id:id
            })
            this.props.setScreen(2,id)
        }
        this.setState({
            safeType:safeType,
            tradeType:tradeType
        })
    }
    handelChnage(event){
        let value=event.target.value;
        this.props.change(value);
    }
    render(){
        const safeType=this.state.safeType;
        const tradeType=this.state.tradeType;
        return <Div>
            <div className="single-filter">
                <span className="title">资源分类：</span>
                <div className="child-style trade">
                    {
                        safeType.map((val,key)=>{
                            return <span key={"key"+key} 
                                className={val.check?"active":""}
                                onClick={this.handelChange.bind(this,1,key)}
                            >
                                {val.name}
                            </span>
                        })
                    }
                </div>
                <div className="search-model">
                    <input type="text" 
                        placeholder="请输入资源名称" 
                        onChange={this.handelChnage.bind(this)}
                        value={this.state.screen.search}
                    />
                    <span>搜索</span>
                </div>
            </div>
            {
                tradeType.length>0&&
                <div className="single-filter">
                    <span className="title">安全分类：</span>
                    <div className="child-style safe">
                        {
                            tradeType.map((val,key)=>{
                                return <span key={"key"+key}
                                    className={val.check?"active":""}
                                    onClick={this.handelChange.bind(this,2,key)}
                                >
                                    {val.name}
                                </span>
                            })
                        }
                    </div>
                </div>
            }
        </Div>
    }
}
export default Filtrate;