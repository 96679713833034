import React, { Component } from 'react';
import styled from 'styled-components';
import Filtrate from './filtrate';
import HotGoods from './hotGoods';
import GoodsList from './list';

const Div=styled.div.attrs({className:"submodule"})`
    width: 1200px;
    margin:0 auto;
    padding-bottom:50px;
    .goods-left,.goods-right{
        display: inline-block;
        vertical-align:top; 
    }
    .goods-left{
        width: 900px;
        min-height:100px;
    }
    .goods-right{
        width: 280px;
        margin-left:20px;
        min-height:100px;
    }
    .all-goods{
        background:#fff;
        line-height:50px;
        font-size:16px; 
        b{
            display: inline-block;
            height: 20px;
            width: 4px;
            vertical-align: middle;
            background:#7059EF;
            margin-left:10px;
            border-radius:3px;
            margin-right:13px;
        }
    }
`
class List extends Component{
    constructor(props){
        super(props);
        this.state={
            screen:{
                tag_id:"",
                ordering:"",
                start_price:"",
                end_price:"",
                owner:""
            },
            type:""
        }
    }
    componentDidMount(){
        document.body.style.background="#F4F4F4";
    }
    componentWillUnmount(){
        document.body.style.background="#fff";
        this.setState=(state,callback)=>{
            return
        }
    }
    // type=1 表示更改类型，type=表示更改排序，
    // type=2的情况下 id=""为全部，id=1，表示为热门兑换，id=2表示我能兑换，
    // type=3,表示价格的排序，
    setScreen(type,id){
        let screen=this.state.screen;
        if(type===1){
            screen.tag_id=id;
        }else if(type===2){
            //当选择全部的时候，清空所以的筛选条件
            if(id===""){
                screen.ordering="";
                screen.owner="";
                screen.start_price="";
                screen.end_price="";
            }else if(id==="1"){
                screen.ordering="-saled_amount";
                screen.owner="";
            }else if(id==="2"){
                screen.owner="my";
                if(screen.ordering==="price"||screen.ordering==="-price"){
                    return
                }else{
                    screen.ordering=""
                }
            }
            //判读文字的显示
            this.setState({
                type:id
            })
        }else if(type===3){
            if(id===1){
                screen.ordering="price"
            }else if(id===2){
                screen.ordering="-price"
            }
        }
        this.setState({
            screen:screen
        })
    }
    //设置价格区间
    setPrice(start,end){
        let screen=this.state.screen;
        screen.start_price=start;
        screen.end_price=end;
        this.setState({
            screen:screen
        })
    }
    render(){
        return <Div>
            <div className="goods-left">
                {/* 筛选 */}
                <div>
                    <Filtrate 
                        setScreen={this.setScreen.bind(this)}
                        setPrice={this.setPrice.bind(this)}
                    />
                </div>
                <div className="all-goods"><b></b>
                    {this.state.type===""&&"全部商品"}
                    {this.state.type==="1"&&"热门兑换"}
                    {this.state.type==="2"&&"我能兑换"}
                </div>
                {/* 商品列表 */}
                <div>
                    <GoodsList 
                        screen={this.state.screen}
                        key={JSON.stringify(this.state.screen)}
                    />
                </div>
            </div>
            {/* 热门商品 */}
            <div className="goods-right">
                <HotGoods />
            </div>
        </Div>
    }
}
export default List