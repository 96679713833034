import React, { Component } from 'react';
import {
	Router,
	Route,
	Switch
} from 'react-router-dom';
import history from '../communal/history';
import List from './goodsList';
import Detail from './goodsDetail';
import Order from './order';

class Index extends Component{
    componentDidMount(){
        document.title="礼品兑换";
    }
    render(){
        return <div>
                <Router history={history}>
                    <Switch>
                        <Route exact path="/shop" component={()=>{
                            return <List />
                        }}/>
                        <Route path="/shop/detail/:id" component={(param)=>{
                            return <Detail param={param}/>
                        }}/>
                        <Route path="/shop/order/:id" component={(param)=>{
                            return <Order param={param}/>
                        }}/>
                    </Switch>
                </Router>
        </div>
    }
}
export default Index;