import React, { Component } from 'react';
import Carousel from './carousel';
import styled from 'styled-components';
import Community from '../../images/Community_Features.png';
import Ecomodel from '../../images/ecomodel.png';
import Partner from './partner';
import $ from 'jquery'
import Fetch from '../communal/Fetch';
import {Alert} from '../communal/utility';

const Div=styled.div`
    .Community-Features{
        position: relative;
        img{
            width: 100%;
        }
        .title{
            position: absolute;
            top:0px;
            left:0px;
            width: 100%;
            text-align:center; 
            padding-top:30px;
            span{
                font-size:30px;
                color:#333;
                font-weight:bold;
                padding:20px 0; 
                position: relative;
                b{
                    position: absolute;
                    bottom:0px;
                    left:10%;
                    width: 80%;
                    height: 5px;
                    border-radius:5px;
                    background:#7059EF;
                }
            }
        }
        .partner{
            width: 1200px;
            margin: 0 auto;
            padding-top: 140px;
        } 
    }
    .user-login{
        z-index:9999; 
        text-align:center;
        margin-top:100px;
        input{
            height: 50px;
            width: 300px;
            padding:0 20px;
            border:1px solid #ddd;
        } 
        button{
            height: 50px;
            width: 100px;
            background:#7059EF;
            color:#fff;
            border-radius:10px;
            margin-left:30px;
            cursor: pointer;
        }
    }
`
class Home extends Component{
    constructor(props){
        super(props);
        this.state={
            name:""
        }
    }
    componentDidMount(){
        document.title="洞明"
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelChange(event){
        this.setState({
            name:event.target.value
        })
    }
    handelLogin(){
        Fetch.post("/api/user/login/",{
            body:JSON.stringify({username:this.state.name}),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            Alert("success","登录成功")
        })
    }
    handelLogout(){
        $.ajax({
            url: "/api/user/logout/",
            type: "GET",
            success: function (data) {
                window.location.reload();
            }
        })
    }
    render(){
        return <Div>
            {/* <div className="user-login">
                <input type="text" value={this.state.name} onChange={this.handelChange.bind(this)}/>
                <button onClick={this.handelLogin.bind(this)}>登录</button>
                <button onClick={this.handelLogout.bind(this)}>登出</button>
            </div> */}
            {/* 轮播图 */}
            <div className="Carousel">
                <Carousel/>
            </div>
            {/* 社区特色 */}
            <div className="Community-Features">
                <div className="title">
                    <span>
                        社区特色
                        <b></b>
                    </span>
                </div>
                <img src={Community} alt=""/>
            </div>
             {/* 生态模式 */}
             <div className="Community-Features">
                <div className="title">
                    <span>
                        生态模式
                        <b></b>
                    </span>
                </div>
                <img src={Ecomodel} alt=""/>
            </div>
            {/* 合作伙伴 */}
            <div className="Community-Features">
                <div className="title">
                    <span>
                        生态伙伴
                        <b></b>
                    </span>
                </div>
                <div className="partner">
                    <Partner />
                </div>
            </div>
        </Div>
    }
}
export default Home;
