import React, { Component } from 'react';
import styled from 'styled-components';
import Fetch from '../communal/Fetch';

const Div=styled.div.attrs({className:"submodule"})`
    width: 1200px;
    margin:0 auto;
    padding-bottom:50px;
    .single-product{
        padding-top:70px;
        .product-photo,.product-introduce{
            display: inline-block;
            vertical-align:top; 
            box-sizing:border-box;
        }
        .product-photo{
            width: 66%;
            img{
                width: 100%;
            }
        }
        .product-introduce{
            width: 34%;
            margin-top:130px;
            padding:0 30px;
            .logo{
                position: relative;
                span{
                    position: absolute;
                    display: inline-block;
                    width: 120px;
                    height: 5px;
                    background:#7059EF;
                    bottom:-6px;
                    left:0px;
                    border-radius:3px;
                    b{
                        display: inline-block;
                        position: absolute;
                        top:-3px;
                        left:0px;
                        width: 10px;
                        height: 10px;
                        border-radius:50%;
                        background:#7059EF;
                    }
                }
            }
            .name{
                line-height:70px; 
                font-weight:bold;
                font-size:25px; 
            }
            .describe{
                font-size:14px;
                line-height:20px; 
            }
            .detail-btn{
                margin-top:20px;
                span{
                    padding:7px 20px;
                    background:#7059EF;
                    color:#fff;
                    font-size:16px;
                    border-radius:11px;
                    cursor: pointer;
                }
            }
        }
    }
    .single-product:nth-child(even)>.product-photo{
        float: right;
    }
    .single-product:nth-child(even)>.product-introduce{
        float: left;
    }
`
class EcoDeck extends Component{
    constructor(props){
        super(props);
        this.state={
            list:[]
        }
    }
    componentDidMount(){
        document.title="社区生态";
        Fetch.get("/api/ecosystems/").then(data=>{
            this.setState({
                list:data
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelHref(link){
        if(link!==""&&link!=="#"){
            window.open(link)
        }
    }
    render(){
        const list=this.state.list;
        return <Div>
            {
                list.map((val,key)=>{
                    return <div className="single-product" key={"key"+key}>
                        <div className="product-photo">
                            <img src={val.image} alt=""/>
                        </div>
                        <div className="product-introduce">
                            <div className="logo">
                                <img src={val.logo} alt=""/>
                                <span><b></b></span>
                            </div>
                            <div className="name">
                                {val.name}
                            </div>
                            <div className="describe">
                                {val.desc}
                            </div>
                            <div className="detail-btn">
                                <span onClick={this.handelHref.bind(this,val.link)}>立即查看</span>
                            </div>
                        </div>
                        <div style={{clear:"both"}}></div>
                    </div>
                })
            }
        </Div>
    }
}
export default EcoDeck;