import React, { Component } from 'react';
import styled from 'styled-components';
import history from '../communal/history';
import Fetch from '../communal/Fetch';
import {Alert} from '../communal/utility';

const Div=styled.div.attrs({className:"submodule"})`
    width: 1200px;
    margin:0 auto;
    padding-top:20px;
    padding-bottom:50px;
    .goods-detail-bg{
        background: #fff;
        .goods-header-detail{
            padding:20px 30px;
            .photo{
                display: inline-block;
                vertical-align: top;
                width: 30%;
                img{
                    width: 100%;
                }
            }
            .goods-detail{
                display: inline-block;
                vertical-align: top;
                width: 70%;
                padding-left:20px;
                box-sizing:border-box;
                .name{
                    line-height:60px;
                    padding:20px 0;
                    font-size:30px;
                    font-weight:bold; 
                }
                .price{
                    line-height:50px;
                    background:#F5F5F5;
                    font-size:18px;
                    padding:0 20px;
                    b{
                        display: inline-block;
                        color: #ffa39e;
                        font-size:20px;
                        margin-right:5px;
                    }
                }
                .conversion{
                    margin:20px 0;
                    .change-count{
                        display: inline-block;
                        span{
                            display: inline-block;
                            width: 25px;
                            height: 25px;
                            border:1px solid #aaa;
                            text-align:center;
                            cursor: pointer;
                            line-height:25px;
                            color:#333; 
                            box-sizing:border-box;
                            vertical-align: top;
                            margin-top: 0px;
                        }
                        input{
                            height: 25px;
                            // border:1px solid #aaa;
                            width: 30px;
                            text-align:center; 
                            box-sizing:border-box;
                            color:#fff;
                            background:#7059EF;
                        }
                    }
                    .sot-out{
                        margin-left:30px;
                        font-size:16px;
                        color:#333;
                    }
                }
            }
            .submit-btn{
                padding:20px 0px;
                span{
                    display: inline-block;
                    color:#fff;
                    width: 120px;
                    height: 40px;
                    line-height:40px;
                    cursor: pointer; 
                    text-align:center;
                    border-radius:10px; 
                    background: #FF7800;
                }
            }
        }
    }
    .goods-describe{
        margin-top:20px;
        .title{
            margin:0 20px;
            font-size:20px;
            line-height:40px;
            border-bottom:1px solid #ddd;  
        }
        .content{
            padding:20px;
            img{
                max-width:100%;
            }
        }
    }
    .recommend-goods{
        margin-top:20px;
        .title{
            margin:0 20px;
            font-size:20px;
            line-height:40px;
            border-bottom:1px solid #ddd;  
        }
    }
`
class List extends Component{
    constructor(props){
        super(props);
        this.state={
            list:[],
            number:1,
            id:props.param.match.params.id,
            detail:{}
        }
    }
    componentDidMount(){
        document.body.style.background="#F4F4F4";
        Fetch.get("/api/goods/shuffle/?goods_id="+this.state.id).then(data=>{
            this.setState({
                list:data
            })
        })
        Fetch.get("/api/goods/"+this.state.id+"/").then(data=>{
            this.setState({
                detail:data
            },()=>{
                document.getElementsByClassName("content")[0].innerHTML=data.details;
            })
        })
    }
    componentWillUnmount(){
        document.body.style.background="#fff";
        this.setState=(state,callback)=>{
            return
        }
    }
    handelDetail(id){
        history.push("/shop/detail/"+id)
    }
    handelNumber(event){
        let value=event.target.value;
        if(value<1){
            value="";
        }
        this.setState({
            number:value
        })
    }
    handelChange(type){
        let number=this.state.number;
        switch(type){
            case "minus":
                if(number>0){
                    number--
                }
                this.setState({
                    number:number
                })
             break;
             case "add":
                    number++
                    this.setState({
                        number:number
                    })
                 break
            default:
                break
        }
    }
    //点击立即兑换
    submitOrder(){
        let list={
            goods_id:this.state.id,
            num:this.state.number
        };
        Fetch.post("/api/goods/exchange/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            history.push("/shop/order/"+data.order_id)
        }).catch(err=>{
            Alert("error",err.msg)
        })
    }
    render(){
        let list=this.state.list;
        let detail=this.state.detail;
        return <Div>
            <div className="goods-detail-bg">
                <div className="goods-header-detail">
                    <div className="photo">
                        <img src={detail.image} alt=""/>
                    </div>
                    <div className="goods-detail">
                        <div className="name">
                            {detail.name}
                        </div>
                        <div className="price">
                            价格：<b>{detail.price}</b>金币
                        </div>
                        <div className="conversion">
                            <div className="change-count">
                                <span onClick={this.handelChange.bind(this,"minus")}>-</span>
                                <input type="number"value={this.state.number} onChange={this.handelNumber.bind(this)}/>
                                <span onClick={this.handelChange.bind(this,"add")}>+</span>
                            </div>
                            <span className="sot-out">已售：{detail.saled_amount}</span>
                            <span className="sot-out">剩余：{detail.left_amount}</span>
                        </div> 
                        <div className="submit-btn">
                            {
                                (this.state.number>=1&&detail.left_amount>=1&&this.state.number<=detail.left_amount)?
                                <span onClick={this.submitOrder.bind(this)}>立即兑换</span>
                                :<span style={{background:"#999",cursor:"not-allowed"}}>立即兑换</span>
                            }
                        </div>  
                    </div>
                </div>
                <div className="goods-describe">
                    <div className="title">产品描述</div>
                    <div className="content">

                    </div>
                </div>
                <div className="recommend-goods">
                    <div className="title">推荐产品</div>
                    <div>
                        {
                            list.map((val,key)=>{
                                return <div
                                    key={"key"+key}
                                    className="single-goods"
                                >
                                    <div className="goods-photo" onClick={this.handelDetail.bind(this,val.id)}>
                                        <img src={val.image} alt=""/>
                                    </div> 
                                    <div className="goods-name" title={val.name} onClick={this.handelDetail.bind(this,val.id)}>
                                        {val.name}
                                    </div>
                                    <div className="goods-stat">
                                        <span className="goods-price">
                                            金币
                                            <b>{val.price}</b>
                                        </span>
                                        <span className="count">
                                            售出{val.saled_amount}/余量{val.left_amount}
                                        </span>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </Div>
    }
}
export default List