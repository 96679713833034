import React, { Component } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper/dist/js/swiper.js';
import Fetch from '../communal/Fetch';

const Div=styled.div`
    .swiper-container{
        #dian{
            .swiper-pagination-bullet{
                background:#90969A;
                width: 15px;
                height: 15px;
            }
            .swiper-pagination-bullet-active{
                background:#fff;
            }
        }
        .swiper-wrapper{
            cursor: pointer;
            img{
                width: 100%;
            }
        }
    }
`

class Carousel extends Component{
    constructor(props){
        super(props);
        this.state={
            list:[]
        }
    }
    componentDidMount(){
        Fetch.get("/api/images/?page=1&location=2").then(data=>{
            this.setState({
                list:data
            },()=>{
                var mySwiper=new Swiper('.swiper-container', {
                    loop: true,     //循环
                    autoplay: {      //自动播放，注意：直接给autoplay:true的话，在点击之后不能再自动播放了
                        delay: 2000,
                        disableOnInteraction: false,    //户操作swiper之后，是否禁止autoplay。默认为true：停止。
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,    // 允许点击跳转
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                })
                //鼠标移上轮播图停止轮播
                mySwiper.el.onmouseover = function(){
                    mySwiper.autoplay.stop();
                }
                //鼠标移出轮播图开始轮播
                mySwiper.el.onmouseout = function(){
                    mySwiper.autoplay.start();
                }
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handleClick(link){
        if(link!==""&&link!=="#"){
            window.open(link)
        }
    }
    render(){
        return <Div>
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    {
                        this.state.list.length!==0&&
                        this.state.list.map((val,k)=>{
                            return <div key={k+"key"} className="swiper-slide" 
                                        onClick={this.handleClick.bind(this,val.link)}>
                                        <img src={val.path} alt="" />
                                    </div>
                        })
                    }
                </div>
                <div id="dian" className='swiper-pagination'></div>
            </div>
        </Div>
    }
}
export default Carousel;