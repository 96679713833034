import React, { Component } from 'react';
import styled from 'styled-components';
import Header from '../../images/headert.png';
import GreyBg from '../../images/grey_bg.png';
import WhiteBg from '../../images/white_bg.png';
import Computer from '../../images/computer.png';
import Carousel from './detailCarousel';
import Referral from './referral';
import Fetch from '../communal/Fetch';
const Div=styled.div.attrs({className:"submodule"})`
    .detail-header{
        position: relative;
        img{
            width: 100%;
        }
        .header-content{
            position: absolute;
            width: 100%;
            top:0px;
            left:0px;
            .content{
                width: 700px;
                margin:0 auto;
                p{
                    color:#fff;
                    font-size:50px;
                    text-align:center; 
                    line-height: 6vw;
                    margin-top: 3vw;
                }
                .experience-btn{
                    text-align:center; 
                    margin-top:2vw;
                    span{
                        color:#fff;
                        font-size:25px;
                        padding:12px 50px;
                        border-radius:50px;
                        border:2px solid #fff;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .detail-bg{
        width: 100%;
        margin:0 auto;
        display: block;
        position: absolute;
        top:0px;
        left:0px;
    }
    .detail-bottom{
        background:#f4f4f4;
        min-height: 750px;
        position:relative;
        .product-detail-show{
            width: 100%;
            padding-top: 150px;
            z-index:666;
            position: relative;
            .block{
                width: 1200px;
                margin:0 auto;
                .product-detail-show-left,.product-detail-show-right{
                    display: inline-block;
                    vertical-align:top;
                    box-sizing:border-box; 
                }
                .product-detail-show-left{
                    div{
                        background:#fff;
                        line-height: 100px;
                        width: 200px;
                        margin-top:20px;
                        // text-align:center; 
                        cursor: pointer;
                        font-size:18px;
                        span{
                            display: inline-block;
                            text-align:center;
                            width: 80px; 
                        }
                    }
                    .active{
                        color:#7059EF;
                        span{
                            color:#FE21BD;
                        }
                    }
                }
                .product-detail-show-right{
                    width: 950px;
                    margin-left:30px;
                    padding:30px 0;
                }
            }
        }
    }
    .detail-middle{
        position: relative;
        height: 505px;
        .detail-middle-content{
            width: 100%;
            text-align:center; 
            position: absolute;
            top:-3vw;
            left:0px;
            z-index: 666;
            .product-img-show{
                position: absolute;
                width: 100%;
                top:41px;
                left:0px;
                .img-show{
                    width: 906px;
                    height: 567px;
                    margin:0 auto;
                }
            }
        }
    }
    
`
class List extends Component{
    constructor(props){
        super(props);
        this.state={
            type:1,
            id:props.param.match.params.id,
            detail:{},
            richText:""
        }
    }
    componentDidMount(){
       Fetch.get("/api/products/"+this.state.id+"/").then(data=>{
           this.setState({
                detail:data,
                richText:data.brief
           })
       })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelToggle(type){
        let detail=this.state.detail;
        let richText="";
        switch(type){
            case 1:
                richText=detail.brief;
                break
            case 2:
                richText=detail.function;
                break
            case 3:
                richText=detail.advantage;
                break
            case 4:
                richText=detail.scene;
                break
            default:
                break
        }
        this.setState({
            type:type,
            richText:richText
        })
    }
    handelHref(link){
        if(link!==""&&link!=="#"){
            window.open(link)
        }
    }
    render(){
        const detail=this.state.detail;
        return <Div>
            <div className="detail-header">
                <img src={Header} alt=""/>
                <div className="header-content">
                    <div className="content">
                        <p>{detail.slogon}</p>
                        {
                            detail.link!==""&&detail.link!=="#"&&
                            <div className="experience-btn">
                                <span onClick={this.handelHref.bind(this,detail.link)}>体验</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="detail-middle">
                <img className="detail-bg" src={WhiteBg} alt=""/>
                <div className="detail-middle-content">
                    <img src={Computer} alt=""/>
                    <div className="product-img-show">
                        <div className="img-show">
                            {
                                detail.productimage_set!==undefined&&
                                <Carousel list={detail.productimage_set}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="detail-bottom">
                <img className="detail-bg" src={GreyBg} alt=""/>
                <div className="product-detail-show">
                    <div className="block">
                        <div className="product-detail-show-left">
                            <div className={this.state.type===1?"active":""} onClick={this.handelToggle.bind(this,1)}>
                                <span>01</span>
                                产品介绍
                            </div>
                            <div className={this.state.type===2?"active":""} onClick={this.handelToggle.bind(this,2)}>
                                <span>02</span>
                                产品功能
                            </div>
                            <div className={this.state.type===3?"active":""} onClick={this.handelToggle.bind(this,3)}>
                                <span>03</span>
                                产品优势
                            </div>
                            <div className={this.state.type===4?"active":""} onClick={this.handelToggle.bind(this,4)}>
                                <span>04</span>
                                产品场景
                            </div>
                        </div>
                        <div className="product-detail-show-right">
                            <Referral richText={this.state.richText} key={this.state.richText}/>
                        </div>
                        <div style={{clear:"both"}}></div>
                    </div>
                </div>
            </div>
        </Div>
    }
}
export default List;