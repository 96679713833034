import React, { Component } from 'react';
import styled from 'styled-components';

const Div = styled.div`
    .show-text{
        img{
            max-width:100%;
        }
    }
`
class Referral extends Component {
    componentDidMount() {
        document.getElementsByClassName("show-text")[0].innerHTML = this.props.richText;
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }
    render() {
        return <Div>
            <div className="show-text"></div>
        </Div>
    }
}
export default Referral