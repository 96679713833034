import React, { Component } from 'react';
import styled from 'styled-components';
import Copy from '../../images/icon/copy.png';
import {Alert} from '../communal/utility'
import Fetch from '../communal/Fetch';
import Page from '../communal/Page';

const Div=styled.div`
    padding:30px 0;
    .consume-title{
        line-height:40px;
        font-size:18px;
        font-weight:bold;
        padding: 0 30px;  
    }
    .single-goods-record{
        display: inline-block;
        vertical-align:top;
        width: 32%;
        margin:0 0.66%;
        margin-top:20px;
        border:1px solid #ddd;
        border-radius:5px;
        padding: 10px 10px;
        .goods-img{
            width: 80px;
            height: 80px;
            vertical-align:top; 
        }
        .goods-record{
            display: inline-block;
            vertical-align:top; 
            width: 210px;
            padding-left: 10px;
            box-sizing: border-box;
            .title{
                .name{
                    display: inline-block;
                    width: 160px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size:16px;
                    font-weight:bold;
                }
                span{
                    float: right;
                    font-weight:bold; 
                    font-size:16px;
                    b{
                        color:#FF6600;
                        font-size:18px;
                    }
                }
            }
            .order-state{
                line-height:30px;
                font-size:14px; 
                color:#333;
            }
            .order-time{
                line-height:30px;
                font-size:12px; 
                color:#999;
            }
        }
    }
    .single-goods-record-bottom{
        height: 30px;
        .not-message{
            line-height:30px;
            font-size:15px;
            color:#999; 
        }
        .order-number{
            color:#333;
            line-height:30px;
            font-size:15px;
            img{
                cursor: pointer;
            }
        }
    }
`
class Info extends Component{
    constructor(props){
        super(props);
        this.state={
            filter:{
                page:1,
                size:12,
                status:"paid"
            },
            list:[],
            allCount:0
        }
    }
    componentDidMount(){
        this.orderRecord();
    }
    //获取兑换记录
    orderRecord(){
        Fetch.get("/api/user/orders/",this.state.filter).then(data=>{
            this.setState({
                list:data.results,
                allCount:data.count
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelCopy(){
        var Url2=document.getElementsByClassName("copy-text")[0];
        Url2.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        Alert("success","已复制成功")
    }
    getPages(page){
        let filter=this.state.filter;
        filter.page=page;
        this.setState({
            filter:filter
        },()=>{
            this.orderRecord();
        })
    }
    render(){
        let list=this.state.list;
        return <Div>
            <div className="consume-title">
                兑换商品记录
            </div>
            {
                list.map((val,key)=>{
                    return <div 
                        className="single-goods-record"
                        key={"key"+key}
                    >
                    <div className="single-goods-record-top">
                        <img className="goods-img" src={val.goods_image} alt=""/>
                        <div className="goods-record">
                            <div className="title">
                                <div className="name">{val.goods_name}</div>
                                <span>
                                    <b>{val.price}</b>
                                    金币
                                </span>
                            </div>
                            <div className="order-state">
                                {
                                    {
                                        1:"待确认",
                                        2:"已确认",
                                        3:"待发货",
                                        4:"备货中",
                                        5:"已发货",
                                        6:"已收货",
                                        7:"拒绝兑换",
                                        8:"待付款",
                                        9:"付款失败"
                                    }[val.status]
                                }
                            </div>
                            <div className="order-time">
                                兑换时间：{val.create_time}
                            </div>
                        </div>
                    </div>
                    <div className="single-goods-record-bottom">
                        {
                            val.status===5?
                            <div className="order-number">
                                物流单号：
                                <input className="copy-text" type="text" value={val.delivery_number}  readOnly/>
                                <img src={Copy} onClick={this.handelCopy.bind(this)} alt=""/>
                            </div>:
                            <div className="not-message">
                                暂无物流信息
                            </div>
                        }
                        
                    </div>
                </div>
                })
            }
            {
                list.length===0&&
                <div className="not-data" style={{fontSize:"25px"}}>暂无兑换记录</div>
            }
            <Page
                key="page"
                upDown
                pageNumber
                allNumber
                // jumpNumber
                // selectNumber
                nowpage={this.state.filter.page} //当前页
                allPage={this.state.allCount}
                everyPage={this.state.filter.size}
                onClick={this.getPages.bind(this)}
                onChange={this.getPages.bind(this)}
            />
        </Div>
    }
}
export default Info;