import React, { Component } from 'react';
import styled from 'styled-components';
import Fetch from '../communal/Fetch';
const Div=styled.div`
    img{
        width: 100%;
        cursor: pointer;
    }
`
class Settled extends Component{
    constructor(props){
        super(props);
        this.state={
            settled:[]
        }
    }
    componentDidMount(){
        Fetch.get("/api/images/?page=2&location=5").then(data=>{
            this.setState({
                settled:data
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelHref(){
        window.open("/#/matrix/apply")
    }
    render(){
        const settled=this.state.settled;
        return <Div>
            {
                settled.length>0&&
                <img onClick={this.handelHref.bind(this)} src={settled[0].path} alt=""/>
            }
        </Div>
    }
}
export default Settled;