import React, { Component } from 'react';
import styled from 'styled-components';
import Carousel from './carousel';
import Settled from './Settled';
import Filtrate from './filtrate';
import ProductList from './productList';

const Div=styled.div.attrs({className:"submodule"})`
    padding-bottom:50px;
    .set-width{
        width: 1200px;
        margin: 0 auto;
    }
`
class List extends Component{
    constructor(props){
        super(props);
        this.state={
            screen:{
                sec_id:"",
                indus_id:"",
                search:"",
            } 
        }
    }
    componentDidMount(){
        document.body.style.background="#F4F4F4";
    }
    componentWillUnmount(){
        document.body.style.background="#fff";
        this.setState=(state,callback)=>{
            return
        }
    }
    setScreen(type,id){
        let screen=this.state.screen;
        if(type===1){
            screen.sec_id=id
        }else if(type===2){
            screen.indus_id=id
        }
        this.setState({
            screen:screen
        })
    }
    inputChange(value){
        let screen=this.state.screen;
        screen.search=value;
        this.setState({
            screen:screen
        })
    }
    render(){
        return <Div>
            {/* 轮播图 */}
            <div>
                <Carousel/>
            </div>
            {/* 申请入驻 */}
            <div>
               <Settled />
            </div>
            {/* 筛选条件 */}
            <div className="set-width">
                <Filtrate 
                    setScreen={this.setScreen.bind(this)} 
                    change={this.inputChange.bind(this)}
                    screen={this.state.screen}
                />
            </div>
            {/* 列表 */}
            <div className="set-width">
                <ProductList 
                    screen={this.state.screen}
                    key={JSON.stringify(this.state.screen)}
                />
            </div>
        </Div>
    }
}
export default List;