import React, { Component } from 'react';
import styled from 'styled-components';
import Bg01 from '../../images/icon/bg01.png';
import Bg02 from '../../images/icon/bg02.png';
import Bg03 from '../../images/icon/bg03.png';
import Bg04 from '../../images/icon/bg04.png';
import Bg05 from '../../images/icon/bg05.png';
import Conversion from '../../images/icon/conversion.png';
import RecordGold from '../../images/icon/record_gold.png';
import Direction from '../../images/icon/direction.png';
import Fetch from '../communal/Fetch';
import CashBox from './cashBox';
import Page from '../communal/Page';


const Div=styled.div`
    padding:30px 0;
    .wallet-title{
        line-height:40px; 
        font-size:18px;
        font-weight:bold; 
        color:#000;
        padding:0 20px;
    }
    .currency{
        div{
            display: inline-block;
            width: 20%;
            height: 110px;
            line-height:45px;
            color:#fff; 
            padding:10px 20px;
            font-size:25px;
            span{
                display: inline-block;
                width: 100%;
            }
        }
        .gold{
            background:${props=>{
                return "url("+Bg01+") no-repeat";
            }}
        }
        .token{
            background:${props=>{
                return "url("+Bg02+") no-repeat";
            }}
        }
        .cloud-currency{
            background:${props=>{
                return "url("+Bg03+") no-repeat";
            }}
        }
        .money-reward{
            background:${props=>{
                return "url("+Bg04+") no-repeat";
            }}
        }
        .award{
            background:${props=>{
                return "url("+Bg05+") no-repeat";
            }}
        }
    }
    .mutual-exchange{
        div{
            display: inline-block;
            width: 20%;
            text-align:center;
            span{
                display: inline-block;
                border-radius:15px;
                padding:2px 15px;
                color:#fff;
                font-size:15px;
                cursor: pointer;
                img{
                    vertical-align:middle;
                    margin-top:-3px;
                    margin-right:5px; 
                }
            } 
            .gold{
                background-image: -moz-linear-gradient( -180deg, rgb(255,187,55) 0%, rgb(255,121,55) 100%);
                background-image: -webkit-linear-gradient( -180deg, rgb(255,187,55) 0%, rgb(255,121,55) 100%);
                background-image: -ms-linear-gradient( -180deg, rgb(255,187,55) 0%, rgb(255,121,55) 100%);
            }
            .token{
                background-image: -moz-linear-gradient( 0deg, rgb(71,82,232) 0%, rgb(73,135,238) 100%);
                background-image: -webkit-linear-gradient( 0deg, rgb(71,82,232) 0%, rgb(73,135,238) 100%);
                background-image: -ms-linear-gradient( 0deg, rgb(71,82,232) 0%, rgb(73,135,238) 100%);
            }
            .cloud-currency{
                background-image: -moz-linear-gradient( 0deg, rgb(235,61,115) 0%, rgb(255,137,180) 100%);
                background-image: -webkit-linear-gradient( 0deg, rgb(235,61,115) 0%, rgb(255,137,180) 100%);
                background-image: -ms-linear-gradient( 0deg, rgb(235,61,115) 0%, rgb(255,137,180) 100%);
            }
            .money-reward{
                background-image: -moz-linear-gradient( 0deg, rgb(157,91,249) 0%, rgb(168,112,248) 100%);
                background-image: -webkit-linear-gradient( 0deg, rgb(157,91,249) 0%, rgb(168,112,248) 100%);
                background-image: -ms-linear-gradient( 0deg, rgb(157,91,249) 0%, rgb(168,112,248) 100%);
            }
            .award{
                background-image: -moz-linear-gradient( 180deg, rgb(101,207,253) 0%, rgb(92,228,142) 100%);
                background-image: -webkit-linear-gradient( 180deg, rgb(101,207,253) 0%, rgb(92,228,142) 100%);
                background-image: -ms-linear-gradient( 180deg, rgb(101,207,253) 0%, rgb(92,228,142) 100%);
            }
        }
    }
    .balance{
        padding-bottom:30px;
        border-bottom:1px solid #ddd;
    }
    .record{
        margin-top:20px;
    }
    .single-record{
        display: inline-block;
        vertical-align:top;
        width: 32%;
        margin:0 0.66%;
        margin-top:20px;
        border:1px solid #ddd;
        border-radius:5px;
        padding: 10px 10px;
        .record-img{
            vertical-align:top;
            // margin-top:20px; 
        }
        .block{
            display: inline-block;
            padding-left:20px;
            width: 255px;
            .exchange-gold{
                line-height:30px; 
                b{
                    color:#FF6600;
                    font-size:20px;
                    display: inline-block;
                    margin-right:5px;
                    max-width:60px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    vertical-align: top;
                }
                img{
                    vertical-align:middle;
                    margin:0 10px;
                    margin-top:-6px; 
                }
            }
            .record-time{
                color:#666;
                font-size:14px;
                line-height:30px; 
                span{
                    float: right;
                    font-size:12px;
                    color:#999;
                }
            }
        }
    }
`
class Info extends Component{
    constructor(props){
        super(props);
        this.state={
            wallet:{},
            origin:"",
            terminus:"",
            amount:"",
            cashShow:false,
            filter:{
                size:12,
                page:1
            },
            allCount:0,
            list:[]
        }
    }
    componentDidMount(){
        this.getWallet();
        this.getRecords();
    }
    //获取钱包剩余金额
    getWallet(){
        Fetch.get("/api/user/wallet/").then(data=>{
            this.setState({
                wallet:data
            })
        })
    }
    //获取兑换记录
    getRecords(){
        let filter=this.state.filter;
        Fetch.get("/api/user/exchange_records/",filter).then(data=>{
            this.setState({
                allCount:data.count,
                list:data.results
            })
            let count=0;
            for(var i=0;i<data.results.length;i++){
                if(data.results[i].status==="兑换中"){
                    count++
                }
            }
            window.clearTimeout(this.timer)
            if(count>0){
                var that=this;
                this.timer = window.setTimeout(function() {
                    that.getRecords();
                },5000)
            }
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelCash(type,show){
        let origin=this.state.origin;
        let terminus=this.state.terminus;
        let amount=this.state.amount;
        let wallet=this.state.wallet;
        switch(type){
            case 0:
                origin="";
                terminus="";
                amount="";
                break;
            case 1:
                origin=1;
                terminus=2;
                amount=wallet.dongming;
                break;
            case 2:
                origin=3;
                terminus=1;
                amount=wallet.luntan;
                break;
            case 3:
                origin=2;
                terminus=1;
                amount=wallet.yunyan;
                break;
            case 4:
                origin=4;
                terminus=1;
                amount=wallet.yanxing;
                break; 
            case 5:
                origin=5;
                terminus=1;
                amount=wallet.ziyuantijao;
                break; 
            default:
                break
        }
        if(!show){
            this.getWallet();
            this.getRecords();
        }
        this.setState({
            origin:origin,
            terminus:terminus,
            cashShow:show,
            amount:amount
        })
    }
    //翻页
    getPages(page){
        let filter=this.state.filter;
        filter.page=page;
        this.setState({
            filter:filter
        },()=>{
            this.getRecords();
        })
    }
    render(){
        const wallet=this.state.wallet;
        const list=this.state.list;
        return <Div>
            <div className="balance">
                <div className="wallet-title">账户余额</div>
                <div className="currency">
                    <div className="gold">
                        <span>金币</span>
                        <span>{wallet.dongming}</span>
                    </div>
                    <div className="token">
                        <span>代币</span>
                        <span>{wallet.luntan}</span>
                    </div>
                    <div className="cloud-currency">
                        <span>云币</span>
                        <span>{wallet.yunyan}</span>
                    </div>
                    <div className="money-reward">
                        <span>赏金</span>
                        <span>{wallet.yanxing}</span>
                    </div>
                    <div className="award">
                        <span>奖励</span>
                        <span>{wallet.ziyuantijao}</span>
                    </div>
                </div>
                <div className="mutual-exchange">
                    <div>
                        <span className="gold" onClick={this.handelCash.bind(this,1,true)}>
                            <img src={Conversion} alt=""/>
                            兑换为云币
                        </span>
                    </div>
                    <div>
                        <span className="token" onClick={this.handelCash.bind(this,2,true)}>
                            <img src={Conversion} alt=""/>
                            兑换为金币
                        </span>
                    </div>
                    <div>
                        <span className="cloud-currency" onClick={this.handelCash.bind(this,3,true)}>
                            <img src={Conversion} alt=""/>
                            兑换为金币
                        </span>
                    </div>
                    <div>
                        <span className="money-reward" onClick={this.handelCash.bind(this,4,true)}>
                            <img src={Conversion} alt=""/>
                            兑换为金币
                        </span>
                    </div>
                    <div>
                        <span className="award" onClick={this.handelCash.bind(this,5,true)}>
                            <img src={Conversion} alt=""/>
                            兑换为金币
                        </span>
                    </div>
                </div>
            </div>
            <div className="record">
                <div className="wallet-title">兑换记录</div>
                {
                    list.map((val,key)=>{
                        return <div className="single-record" key={key+"key"}>
                        <img className="record-img" src={RecordGold} alt=""/>
                        <div className="block">
                            <div className="exchange-gold">
                                <b title={Math.abs(val.delta_from)+".00"}>{Math.abs(val.delta_from)}.00</b>
                                {
                                    {
                                        1:"金币",
                                        2:"云币",
                                        3:"代币",
                                        4:"赏金",
                                        5:"奖励"
                                    }[val.exchange_from]
                                }
                                <img src={Direction} alt=""/>
                                <b title={val.delta_to}>{val.delta_to}</b>
                                {
                                    {
                                        1:"金币",
                                        2:"云币"
                                    }[val.exchange_to]
                                } 
                            </div>
                            <div className="record-time">
                                {val.status}
                                <span>{val.create_time}</span>
                            </div>
                        </div>
                    </div>
                    })
                }
                {
                    list.length===0&&
                    <div className="not-data" style={{fontSize:"25px"}}>暂无兑换记录</div>
                }
                <Page
                    key="page"
                    upDown
                    pageNumber
                    allNumber
                    // jumpNumber
                    // selectNumber
                    nowpage={this.state.filter.page} //当前页
                    allPage={this.state.allCount}
                    everyPage={this.state.filter.size}
                    onClick={this.getPages.bind(this)}
                    onChange={this.getPages.bind(this)}
                />
            </div>
            {
                this.state.cashShow&&
                <CashBox 
                    close={this.handelCash.bind(this,0,false)} 
                    origin={this.state.origin} 
                    terminus={this.state.terminus}
                    amount={this.state.amount}
                />
            }
        </Div>
    }
}
export default Info;