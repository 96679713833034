import React, { Component } from 'react';
import styled from 'styled-components';
import Fetch from '../communal/Fetch';
import {connect} from "react-redux";
const Div=styled.div`
    background:#fff;
    padding:15px;
    margin:10px 0;
    .single-filter{
        margin:10px 0;
        .title{
            display: inline-block;
            vertical-align:top; 
            font-size:16px;
            font-weight:bold; 
            line-height:40px; 
        }
        .child-style{
            display: inline-block;
            vertical-align:top; 
            // height: 40px;
            max-width: calc(100% - 50px);
            span{
                display: inline-block;
                color: #333;
                vertical-align:middle; 
                padding: 5px 15px;
                border-radius: 18px;
                font-size: 14px;
                margin:0 5px;
                margin-top: 5px;
                cursor: pointer;
            }
            .not-check{
                cursor:not-allowed;
                color:#aaa;
            }
            .active{
                background: #7059EF;
                color: #fff;
            }
        }
        .price-screen{
            display: inline-block;
            vertical-align:middle; 
            line-height:40px; 
            margin-left:20px;
            cursor: pointer;
            .price-sort{
                position: relative;
                i{
                    position: absolute;
                    font-size:16px;
                    color:#999;
                    margin-left:5px;
                }
                .fa-caret-down{
                    bottom:-4px;
                }
                .fa-caret-up{
                    top:-4px;
                }
                .active{
                    color:#7059EF;
                }
            }
            .price-range{
                display: inline-block;
                height: 40px;
                borderradius:1px;
                box-sizing:border-box;
                margin-left:20px;
                border:1px solid transparent;
                line-height: 37px;
                input{
                    vertical-align:middle;
                    margin-top:-2px; 
                    border:1px solid #ddd;
                    height: 25px;
                    padding:0 10px;
                    margin:0 10px;
                    width: 60px;
                }
                .submit-price{
                    display: inline-block;
                    background:#7059EF;
                    color:#fff;
                    margin:0 10px;
                    height:25px;
                    line-height:25px; 
                    vertical-align:middle; 
                    font-size:12px;
                    padding:0 15px;
                    border-radius:5px;
                    cursor: pointer;
                    display: none;
                }
            }
            .price-range:hover{
                border:1px solid #ddd;
            }
            .price-range:hover .submit-price{
                display: inline-block;
            }
        }
    }
`
class Filtrate extends Component{
    constructor(props){
        super(props);
        this.state={
            safeType:[],
            tradeType:[
                {
                    id:"",
                    name:"全部",
                    check:true
                },
                {
                    id:"1",
                    name:"热门兑换"
                },
                {
                    id:"2",
                    name:"我能兑换"
                }
            ],
            screen:this.props.screen,
            start_price:"",
            end_price:"",
            price:""
        }
    }
    componentDidMount(){
        Fetch.get("/api/goods/tags/").then(data=>{
            data.unshift({
                id:"",
                name:"全部",
                check:true
            })
            this.setState({
                safeType:data
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelChange(type,index){
        if(type===2&&!this.props.login&&index===2){
            return
        }
        let safeType=this.state.safeType;
        let tradeType=this.state.tradeType;
        let id="";
        if(type===1){
            for(var i=0;i<safeType.length;i++){
                safeType[i].check=false;
            }
            safeType[index].check=true;
            id=safeType[index].id;
        }else if(type===2){
            for(var j=0;j<tradeType.length;j++){
                tradeType[j].check=false;
            }
            tradeType[index].check=true;
            id=tradeType[index].id;
            //当为热门兑换的时候  要清除价格排序，因为两个筛选用的是一个字段
            if(id==="1"){
                this.setState({
                    price:""
                })
            }
            //当选择全部的时候，清空所以的筛选条件
            if(id===""){
                this.setState({
                    price:"",
                    start_price:"",
                    end_price:""
                })
            }
        }
        this.setState({
            safeType:safeType,
            tradeType:tradeType
        })
        this.props.setScreen(type,id)
    }
    handelChnage(type,event){
        let value=event.target.value;
        if(type===1){
            this.setState({
                start_price:value
            })
        }else if(type===2){
            this.setState({
                end_price:value
            })
        }
    }
    handelPrice(){
        let start_price=Number(this.state.start_price);
        let end_price=Number(this.state.end_price);
        if(start_price>end_price){
            var price=start_price;
            start_price=end_price;
            end_price=price;
            this.setState({
                start_price:start_price,
                end_price:end_price
            })
        }
        this.props.setPrice(start_price,end_price)
    }
    sortPrice(){
        let price=this.state.price;
        if(price!==1){
            price=1
        }else{
            price=2
        }
        this.setState({
            price:price
        })
        this.props.setScreen(3,price)
    }
    render(){
        const safeType=this.state.safeType;
        const tradeType=this.state.tradeType;
        return <Div>
            <div className="single-filter">
                <span className="title">类型：</span>
                <div className="child-style safe">
                    {
                        safeType.map((val,key)=>{
                            return <span key={"key"+key} 
                                className={val.check?"active":""}
                                onClick={this.handelChange.bind(this,1,key)}
                            >
                                {val.name}
                            </span>
                        })
                    }
                </div>
            </div>
            <div className="single-filter">
                <span className="title">排序：</span>
                <div className="child-style trade">
                    {
                        tradeType.map((val,key)=>{
                            return <span key={"key"+key}
                                className={(val.id==="2"&&!this.props.login)?"not-check":val.check?"active":""}
                                onClick={this.handelChange.bind(this,2,key)}
                            >
                                {val.name}
                            </span>
                        })
                    }
                </div>
                <div className="price-screen">
                    <span className="price-sort" onClick={this.sortPrice.bind(this)}>
                        价格
                        <i className={this.state.price===2?"active fa fa-caret-down":"fa fa-caret-down"} aria-hidden="true"></i>
                        <i className={this.state.price===1?"active fa fa-caret-up":"fa fa-caret-up"} aria-hidden="true"></i>
                    </span>
                    <div className="price-range">
                        <input type="number" placeholder="￥" value={this.state.start_price} onChange={this.handelChnage.bind(this,1)}/>
                        <span>—</span>
                        <input type="number" placeholder="￥" value={this.state.end_price} onChange={this.handelChnage.bind(this,2)}/>
                        <span className="submit-price" onClick={this.handelPrice.bind(this)}>确定</span>
                    </div>
                </div>
            </div>
        </Div>
    }
}
const mapStateToProps = (state)=> {
    return {
       login:state.login,
    }
};
export default connect(
   mapStateToProps,
)(Filtrate);