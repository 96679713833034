import React, { Component } from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';
import Address from '../shop/address';
import {connect} from "react-redux";
import Fetch from '../communal/Fetch';
import {setInfo} from '../redux/Actions';
import store from '../redux/Store';
import realmName from "../../json/realmName.json";
import $ from 'jquery';
import {Alert} from '../communal/utility';

const Div=styled.div`
    padding: 20px 30px;
    .info-title{
        line-height:40px; 
        font-size:18px;
        font-weight:bold; 
        color:#000;
    }
    .account-info{
        .avatar{
            padding:0 100px;
            .img,.prompt{
                display:inline-block;
                vertical-align:top; 
            }
            .img{
                position: relative;
                .mask-layer{
                    position: absolute;
                    top:0px;
                    left:0px;
                    bottom:0px;
                    right:0px;
                    background:rgba(0,0,0,.3);
                    border-radius:50%;
                    cursor: pointer;
                    text-align:center;
                    line-height:120px;
                    color: #fff;
                    font-size:18px;  
                }
            }
            .prompt{
                margin-left:30px;
                text-align:center;
                margin-top:30px;
                p{
                    margin-bottom:0px;
                    line-height:30px; 
                    font-size:16px;
                } 
            }
        }
    }
    .singe-message{
        line-height: 50px;
        .title,.block,.modify{
            display: inline-block;
            vertical-align:top; 
        }
        .title{
            width: 80px;
            text-align:right; 
            color:#999;
            font-size:16px;
            margin-right:20px;
        }
        .block{
            width: 400px;
            padding-right:20px;
            box-sizing:border-box;
            color:#333;
            textarea{
                width: 250px;
                height: 60px;
                resize:none;
                line-height:25px;
                padding:5px 10px; 
                border:1px solid #ddd;
                font-size:14px;
            }
            input{
                width: 250px;
                height: 30px;
                border:1px solid #ddd;
                font-size:14px;
                padding:0 10px;
            }
            span{
                display: inline-block;
                vertical-align:top; 
                width: 50px;
                height: 25px;
                color:#fff;
                background:#7059EF;
                line-height:25px;
                text-align:center;
                margin-left:10px;
                font-size:12px;
                cursor: pointer;  
                box-sizing:border-box;
                border-radius:5px;
                margin-top:10px;
            }
            .cancel{
                color:#7059EF;
                border:1px solid #7059EF;
                background:transparent;
            }
        }
        .modify{
            font-size:14px;
            span{
                color:#FF6600;
                display: inline-block;
                cursor: pointer;
                margin-right:20px;
            }
        }
    }
`
class Info extends Component{
    constructor(props){
        super(props);
        this.state={
            isedited:{
                sdasd:false,//是否修改个性签名
                realName:false,//是否修改真实姓名
                gmail:false,//是否修改邮箱
                address:false,//是否修改收货地址
            },
            address:{
                brief:"",//概要地址
                details:"",//详细地址
                postcode:"",//邮政编码
                cosignee:"",//收货人姓名
                phone:"",//收货人电话
            },
            info:props.info
        }
    }
    componentDidMount(){
        this.getAddress();
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    //选择头像
    changeImg(){
        document.getElementsByClassName("file")[0].click();
    }
    //获取地址信息
    getAddress(){
        Fetch.get("/api/user/address/info/").then(data=>{
            this.setState({
                address:data
            })
        })
    }
    //点击修改账号信息和用户信息，type为需要修改的类型 1为修改地址 2为修改个性签名 3为修改真实姓名 value为需要需要的值
    changeMessage(type,value){
        let isedited=this.state.isedited;
        switch(type){
            case 1:
                isedited.address=value;
                this.getAddress();
                break;
            case 2:
                isedited.sdasd=value;
                break;
            case 3:
                isedited.realName=value;
                break;
            case 4:
                isedited.gmail=value;
                break;
            default:
                break;
        }
        this.setState({
            isedited:isedited
        },()=>{
            this.updateInfo();
        })
    }
    //change事件
    handelChange(name,event){
        let info=this.props.info;
        info[name]=event.target.value;
        this.setState({
            info:info
        })
    }
    //更新信息
    updateInfo(){
        Fetch.get("/api/user/info/").then(res=>{
            store.dispatch(setInfo(res));
        })
    }
    //修改登录密码
    handleEditPass(){
        window.open(realmName.sso +"/server_app/#/person/editpass/first/3/" + this.props.uuid)
    }
    //修改手机号
    handleEditPhone(){
        window.open(realmName.sso +"/server_app/#/person/editphone/first/3/"+this.props.uuid)
    }
    //1个性签名 2姓名 3邮箱
    changeInfo(type){
        let info=this.state.info;
        let formData = new FormData();
        switch(type){
            case 1:
                formData.append("signature",info.signature);
                break;
            case 2:
                formData.append("realname",info.realname);
                break;
            case 3:
                formData.append("email",info.email);
                break;
            default:
                break;
        }
        var that=this;
        $.ajax({
            url: "/api/user/info/",
            type: "PUT",
            data: formData,
            contentType: false,
            processData: false,
            success: function (res) {
                that.updateInfo();
                switch(type){
                    case 1:
                        that.changeMessage(2,false);
                        break;
                    case 2:
                        that.changeMessage(3,false);
                        break;
                    case 3:
                        that.changeMessage(4,false);
                        break;
                    default:
                        break;
                }
                Alert("success","个人信息修改成功")
            },
            error: function (err) {
                if(err.responseJSON.signature){
                    Alert("error",err.responseJSON.signature[0])
                }
                if(err.responseJSON.realname){
                    Alert("error",err.responseJSON.realname[0])
                }
                if(err.responseJSON.email){
                    Alert("error",err.responseJSON.email[0])
                }
            }
        });
    }
    handleChangeImg(){
        let info = this.state.info;
        // 拿到图片路径
        let file = document.getElementById("inputImg").files[0];
        // 实时替换图片在页面上
        if(file!==undefined){
            if (window.createObjectURL !== undefined) { // basic
                Object.assign(info, {
                    photo: window.createObjectURL(file)
                })
            } else if (window.URL !== undefined) { // mozilla(firefox)
                Object.assign(info, {
                    photo: window.URL.createObjectURL(file)
                })
            } else if (window.webkitURL !== undefined) { // webkit or chrome
                Object.assign(info, {
                    photo: window.webkitURL.createObjectURL(file)
                })
            }
            // 上传文件必须使用ajax
            var form = document.getElementById('formImg');
            let formData = new FormData(form);
            var that=this;
            $.ajax({
                url: "/api/user/info/",
                type: "PUT",
                data: formData,
                contentType: false,
                processData: false,
                success: function (res) {
                    that.updateInfo();
                },
                error: function (err) {
                    if(err.responseJSON.photo){
                        Alert("error",err.responseJSON.photo[0])
                    }
                }
            });
        }
        
    }
    //点击绑定微信
    handleBangWei(){
        if(this.props.info.sso_user_id>0){
            window.open("https://open.weixin.qq.com/connect/qrconnect?appid=wx07ec764953a9d551&redirect_uri=http%3a%2f%2fwww.yunyansec.com%2fserver_app%2fcallback%2f&response_type=code&scope=snsapi_login&state=" + this.state.sso_user_id +"where=3")
        }
    }
    render(){
        const isedited=this.state.isedited;
        const info=this.props.info;
        const address=this.state.address;
        return <Div>
            <div className="account-info">
                <div className="info-title">账号信息</div>
                <div className="avatar">
                    <div className="img">
                        <Avatar src={info.photo} size={120}/>
                        <span className="mask-layer" onClick={this.changeImg.bind(this)}>更改头像</span>
                        <form id="formImg">
                            <input type="file" id="inputImg" name="photo" className="file" style={{display:"none"}} 
                                accept="image/png, image/jpeg, image/gif, image/jpg" 
                                onChange={this.handleChangeImg.bind(this)}
                            />
                        </form>
                    </div>
                    <div className="prompt">
                        <p>只支持JPG、PNG、GIF，大小不超过5M</p>
                        <p>建议尺寸为100 x 100</p>
                    </div>
                </div>
                 <div className="singe-message">
                    <span className="title">用户名</span>
                    <div className="block">
                        <div>{info.username}</div>
                    </div>
                </div>
                <div className="singe-message">
                    <span className="title">登录密码</span>
                    <div className="block">
                        <div>**********</div>
                    </div>
                    <div className="modify">
                        <span onClick={this.handleEditPass.bind(this)}>修改</span>
                        {/* 上次修改时间：{info.registered_date} */}
                    </div>
                </div>
                <div className="singe-message">
                    <span className="title">个性签名</span>
                    <div className="block">
                        {
                            !isedited.sdasd&&
                            <div>
                                {
                                    info.signature===null?
                                    "这个人很懒，什么都没留下":
                                    info.signature
                                }
                                </div>
                        }
                        {
                            isedited.sdasd&&
                            <div>
                                <textarea value={info.signature===null?"这个人很懒，什么都没留下":info.signature} onChange={this.handelChange.bind(this,"signature")} placeholder="请输入个性签名"></textarea>
                                <span className="cancel" onClick={this.changeMessage.bind(this,2,false)}>取消</span>
                                <span onClick={this.changeInfo.bind(this,1,false)}>确定</span>
                            </div>
                        }
                    </div>
                    {
                        !isedited.sdasd&&
                        <div className="modify">
                            <span onClick={this.changeMessage.bind(this,2,true)}>修改</span>
                        </div>
                    }
                </div>
                <div className="singe-message">
                    <span className="title">注册时间</span>
                    <div className="block">
                        <div>{info.registered_date}</div>
                    </div>
                </div>
            </div>
            <div className="user-info">
                <div className="info-title">用户信息</div>
                <div className="singe-message">
                    <span className="title">姓名</span>
                    <div className="block">
                        {
                            !isedited.realName&&
                            <div>{info.realname===""?"暂未填写":info.realname}</div>
                        }
                        {
                            isedited.realName&&
                            <div>
                                <input type="text" value={info.realname} onChange={this.handelChange.bind(this,"realname")} placeholder="请输入真实姓名"/>
                                <span className="cancel" onClick={this.changeMessage.bind(this,3,false)}>取消</span>
                                <span onClick={this.changeInfo.bind(this,2,false)}>确定</span>
                            </div>
                        }
                    </div>
                    {
                        !isedited.realName&&
                        <div className="modify">
                            <span onClick={this.changeMessage.bind(this,3,true)}>修改</span>
                        </div>
                    }
                </div>
                <div className="singe-message">
                    <span className="title">手机号</span>
                    <div className="block">
                        <div>{info.phone===null?"暂未填写":info.phone}</div>
                    </div>
                    <div className="modify">
                        <span onClick={this.handleEditPhone.bind(this)}>修改</span>
                    </div>
                </div>
                <div className="singe-message">
                    <span className="title">绑定微信</span>
                    <div className="block">
                        <div>{info.wechat===""?"暂未绑定微信":info.wechat}</div>
                    </div>
                    {
                        info.wechat===""&&
                        <div className="modify">
                            <span onClick={this.handleBangWei.bind(this)}>绑定</span>
                        </div>
                    }
                </div>
                <div className="singe-message">
                    <span className="title">绑定邮箱</span>
                    <div className="block">
                        {
                            !isedited.gmail&&
                            <div>{(info.email===null||info.email==="")?"暂未填写":info.email}</div>
                        }
                        {
                            isedited.gmail&&
                            <div>
                                <input type="text" value={info.email} onChange={this.handelChange.bind(this,"email")} placeholder="请输入邮箱"/>
                                <span className="cancel" onClick={this.changeMessage.bind(this,4,false)}>取消</span>
                                <span onClick={this.changeInfo.bind(this,3,false)}>确定</span>
                            </div>
                        }
                    </div>
                    {
                        !isedited.gmail&&
                        <div className="modify">
                            <span onClick={this.changeMessage.bind(this,4,true)}>修改</span>
                        </div>
                    }
                </div>
                <div className="singe-message">
                    <span className="title">收货地址</span>
                    <div className="block">
                        {
                            address.brief===""?
                            <div>暂未填写收货地址</div>
                            :<div>{address.brief} {address.details}</div>
                        }
                    </div>
                    <div className="modify" style={{color:"#FF6600"}}>
                        <span onClick={this.changeMessage.bind(this,1,true)}>修改</span>
                        此地址作为兑换商品邮递地址，请务必真实填写
                    </div>
                </div>
                {/* <div className="singe-message">
                    <span className="title">所属单位</span>
                    <div className="block">
                        <div>{info.company===""?"暂未填写":info.company}</div>
                    </div>
                </div> */}
            </div>
            {
                isedited.address&&
                <Address close={this.changeMessage.bind(this,1,false)}/>
            }
        </Div>
    }
}
const mapStateToProps = (state)=> {
    return {
       info:state.info,
       uuid:state.uuid
    }
};
export default connect(
   mapStateToProps,
)(Info);