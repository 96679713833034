import React, { Component } from 'react';
import styled from 'styled-components';
import Close from '../../images/close.png';
import cashBg from '../../images/cash-bg.png';
import Fetch from '../communal/Fetch';
import {Alert,accSub} from '../communal/utility';

const Div=styled.div`
    background: rgba(0,0,0,.5);
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    z-index:999;
    // display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    .cash-box{
        margin:0 auto;
        width:472px;
        background:#fff;
        min-height:300px;
        .close-img{
            text-align:right;
            padding:6px 15px;
            img{
                cursor:pointer;
            }
        }
        .cash-detail{
            height:247px;
            background:${props=>{
                return "url("+cashBg+") no-repeat"
            }};
            text-align:center;
            padding-top:60px;
            color:#fff;
            .title{
                font-size: 22px;
            }
            .cash-balance{
                font-size:25px;
                line-height:70px;
                b{
                    font-size:30px;
                    display:inline-block;
                    margin-right:5px;
                }
            }
            .note{
                font-size:16px;
            }
        }
        .cash-form{
            padding:0 15px;
            .title{
                line-height: 40px;
            }
            .form-input{
                input{
                    width: 100%;
                    height: 40px;
                    border: 1px solid #ddd;
                    padding: 0 10px;
                }
            }
            .cash-number{
                position:relative;
                input{
                    padding-right:85px;
                }
                span{
                    position:absolute;
                    top:0px;
                    height:40px;
                    line-height:40px;
                    right:10px;
                    color:#7059EF;
                    font-size:16px;
                    cursor:pointer;
                }
            }
            .valida-code{
                input{
                    width:calc(100% - 100px);
                }
                img{
                    cursor:pointer;
                    vertical-align: top;
                    margin-top: 0px;
                }
            }
            .phone-code{
                input{
                    width:calc(100% - 120px);
                    margin-right:15px;
                }
                span{
                    display:inline-block;
                    width:100px;
                    line-height:40px;
                    background:#7059EF;
                    color:#fff;
                    text-align:center;
                    cursor:pointer;
                    border-radius:5px;
                }
            }
        }
    }
    .submit-btn{
        background:#7059EF;
        text-align:center;
        color:#fff;
        line-height:50px;
        font-size:18px;
        margin-top:20px;
        cursor:pointer;
    }
    .convertNumber{
        line-height:20px;
        font-size:14px;
        color:#FF9D75;
    }

`
class Cash extends Component{
    constructor(props){
        super(props);
        this.state={
            graph_code:"api/sec_code/captcha/?"+new Date().getTime(),
            origin:props.origin,
            terminus:props.terminus,
            amount:props.amount,
            rate:{},
            submit:{
                phone:"",
                from_amount:0,
                verify_code:"",
                picture_code:"",
            },
            error:{
                phone:[],
                from_amount:[],
                verify_code:[],
                picture_code:[],
            },
            time_left:0,
        }
    }
    componentDidMount(){
        let height=document.body.clientHeight-document.getElementsByClassName("cash-box")[0].clientHeight;
        if(height>0){
            document.getElementsByClassName("cash-box")[0].style.marginTop=height/2 +"px";
        }
        Fetch.get("/api/user/exchange_rate/?exchange_from="+this.state.origin+"&exchange_to="+this.state.terminus).then(data=>{
            this.setState({
                rate:data
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return;
        }
    }
    //点击刷新图形验证码
    handelRefresh(){
        this.setState({
            graph_code:"api/sec_code/captcha/?"+new Date().getTime()
        })
    }
    handelChange(name,event){
        let submit=this.state.submit;
        submit[name]=event.target.value;
        this.setState({
            submit:submit
        })
    }
    handelFocus(name,event){
        let error=this.state.error;
        error[name]=[];
        this.setState({
            error:error
        })
    }
    //获取短信验证码 /api/sec_code/verify_code/
    handelGetCode(){
        let submit=this.state.submit;
        if(submit.phone===""){
            Alert("error","请您输入正确的手机号码")
            return
        }else if(submit.picture_code===""){
            Alert("error","请您输入正确的图形验证码")
            return
        }
        let list={
            phone:submit.phone,
            picture_code:submit.picture_code
        }
        Fetch.post("/api/sec_code/verify_code/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            Alert("success",data.msg);
            this.setState({
                time_left:data.time_left
            },()=>{
                this.getTimeDown();
            })
        }).catch(err=>{
            Alert("error",err.msg);
            this.handelRefresh();
        })
    }
    //获取验证码倒计时
    getTimeDown(){
        this.timer=setInterval(()=>{
            let time_left=this.state.time_left;
            if(time_left>0){
                time_left--
                this.setState({
                    time_left:time_left
                })
            }else{
                clearInterval(this.timer);
            }
        },1000)
    }
    //点击全部兑换
    handelAllDollars(){
        let submit=this.state.submit;
        submit.from_amount=parseInt(this.state.amount);
        this.setState({
            submit:submit
        })
    }
    //点击确定兑换
    handelSubmit(){
        let submit=this.state.submit;
        let list={
            exchange_from:this.state.origin,
            exchange_to:this.state.terminus,
            verify_code:submit.verify_code,
            phone:submit.phone,
            from_amount:submit.from_amount
        }
        Fetch.post("/api/user/coin_exchange/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            Alert("success",data.msg);
            this.props.close();
        }).catch(err=>{
            if(err.exchange_from){
                Alert("error",err.exchange_from[0])
            }else if(err.exchange_to){
                Alert("error",err.exchange_to[0])
            }else if(err.verify_code){
                Alert("error",err.verify_code[0])
            }else if(err.phone){
                Alert("error",err.phone[0])
            }else if(err.from_amount){
                Alert("error",err.from_amount[0])
            }else if(err.msg){
                Alert("error",err.msg)
            }
        })
    }
    //限制type=number 的输入
    handelKeyPress(event){
        const invalidChars = ['-', '+', 'e', '.', 'E'];   
        if(invalidChars.indexOf(event.key) !== -1){
            event.preventDefault()
        }
    }
    render(){
        const rate=this.state.rate;
        const submit=this.state.submit;
        return <Div>
            <div className="cash-box">
                <div className="close-img">
                    <img src={Close} alt="" onClick={this.props.close}/>
                </div>
                <div className="cash-detail">
                    <div className="title">
                        我的
                        {
                            {
                                1:"金币",
                                2:"云币",
                                3:"代币",
                                4:"赏金",
                                5:"奖励"
                            }[this.state.origin]
                        }
                        余额
                    </div>
                    <div className="cash-balance">
                        <b>{this.state.amount}</b> 
                        {
                            {
                                1:"金币",
                                2:"云币",
                                3:"代币",
                                4:"赏金",
                                5:"奖励"
                            }[this.state.origin]
                        }
                    </div>
                    <div className="note">
                        注： {
                                {
                                    1:"金币",
                                    2:"云币",
                                    3:"代币",
                                    4:"赏金",
                                    5:"奖励"
                                }[this.state.origin]
                            }当前只能兑换为
                            {
                                {
                                    1:"金币",
                                    2:"云币"
                                }[this.state.terminus]
                            }
                            <div>小数点金额凑整了才能兑换哟。</div>
                    </div>
                </div>
                <div className="cash-form">
                    <div className="title">
                        兑换
                        {
                            {
                                1:"金币",
                                2:"云币",
                                3:"代币",
                                4:"赏金",
                                5:"奖励"
                            }[this.state.origin]
                        }
                        数量
                        （
                            {rate.from_num}
                            {
                                {
                                    1:"金币",
                                    2:"云币",
                                    3:"代币",
                                    4:"赏金",
                                    5:"奖励"
                                }[this.state.origin]
                            }
                            =
                            {rate.to_num}
                            {
                                {
                                    1:"金币",
                                    2:"云币"
                                }[this.state.terminus]
                            } 
                        ）
                    </div>
                    <div className="form-input cash-number">
                        <input type="number"
                            value={submit.from_amount} 
                            onKeyPress={this.handelKeyPress.bind(this)}
                            onChange={this.handelChange.bind(this,"from_amount")} 
                            onFocus={this.handelFocus.bind(this,"from_amount")}
                            placeholder={
                                {
                                    1:"请输入兑换金币数量",
                                    2:"请输入兑换云币数量",
                                    3:"请输入兑换代币数量",
                                    4:"请输入兑换赏金数量",
                                    5:"请输入兑换奖励数量"
                                }[this.state.origin]
                            }
                        />
                        <span onClick={this.handelAllDollars.bind(this)}>全部兑换</span>
                        <div className="convertNumber">兑换
                            {
                                {
                                    1:"金币",
                                    2:"云币"
                                }[this.state.terminus]
                            }：{accSub((submit.from_amount/rate.from_num)*rate.to_num)}
                        </div>
                    </div>
                    <div className="title">
                        验证码
                    </div>
                    <div className="form-input valida-code">
                        <input type="text" value={submit.picture_code} onChange={this.handelChange.bind(this,"picture_code")} onFocus={this.handelFocus.bind(this,"picture_code")} placeholder="请输入验证码"/>
                        <img onClick={this.handelRefresh.bind(this)} src={this.state.graph_code} alt=""/>
                    </div>
                    <div className="title">
                        手机号
                    </div>
                    <div className="form-input">
                        <input type="number"onKeyPress={this.handelKeyPress.bind(this)}  value={submit.phone} onChange={this.handelChange.bind(this,"phone")} onFocus={this.handelFocus.bind(this,"phone")} placeholder="请输入您绑定手机号"/>
                    </div>
                    <div className="title">
                        手机验证码
                    </div>
                    <div className="form-input phone-code">
                        <input type="text" value={submit.verify_code} onChange={this.handelChange.bind(this,"verify_code")} onFocus={this.handelFocus.bind(this,"verify_code")} placeholder="请输入获取的手机验证码"/>
                        {
                            this.state.time_left===0?
                            <span onClick={this.handelGetCode.bind(this)}>获取验证码</span>
                            :<span style={{background:"#666",cursor:"not-allowed"}}>{this.state.time_left}S</span>
                        }
                    </div>
                </div>
                <div className="submit-btn" onClick={this.handelSubmit.bind(this)}>
                    立即兑换
                </div>
            </div>
        </Div>
    }
}
export default Cash;