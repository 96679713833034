import React, { Component } from 'react';
import {
	Router,
	Route,
	Switch
} from 'react-router-dom';
import history from '../communal/history';
import List from './list';
import Detail from './detail';
import Apply from './applyFor';

class Index extends Component{
    componentDidMount(){
        document.title="安全矩阵";
    }
    render(){
        return <div>
            <Router history={history}>
                <Switch>
                    <Route exact path="/matrix" component={()=>{
                        return <List />  //安全矩阵列表
                    }}/>
                    <Route path="/matrix/detail/:id" component={(param)=>{
                        return <Detail param={param}/>  //安全矩阵详情
                    }}/>
                    <Route path="/matrix/apply" component={(param)=>{
                        return <Apply param={param}/>  //入驻申请
                    }}/>
                </Switch>
            </Router>
        </div>
    }
}
export default Index