
export function setPathname(pathname){
      return{
        type:"SET_NAME",
        pathname:pathname
      }
  }
export function setLogin(status){
    return{
      type:"SET_LOGIN",
      login:status
    }
}
export function setUuid(uuid){
  return{
    type:"SET_UUID",
    uuid:uuid
  }
}
export function setInfo(info){
  return{
    type:"SET_INFO",
    info:info
  }
}
export function setShow(show){
  return{
    type:"SET_SHOW",
    show:show
  }
}