/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from '../../images/logo.png';
import Wchart from '../../images/wchart.png';
import RealmName from '../../json/realmName.json';
import Icon from '../../images/icon-police.png'
const Div=styled.div`
    min-height:100px;
    background:#000221;
    text-align:center;
    color:#fff;
    .copyright{
        background: #000;
        text-align:center; 
        font-size:14px;
        line-height:40px; 
        a{
            color:#fff;
            margin:0 10px;
            img{
                margin:0 10px;
                vertical-align: middle;
            }
        }
    }
    .information{
        width: 1200px;
        margin:0 auto;
        .inline{
            display: inline-block;
            box-sizing:border-box;
            width: 33%;
            vertical-align:top;
            text-align: left; 
            .title{
                line-height: 50px;
                font-size:18px;
                font-weight:bold; 
                position: relative;
                b{  
                    position: absolute;
                    bottom:0px;
                    left:5px;
                    display: inline-block;
                    height: 3px;
                    width:32px;
                    background:#fff;
                }
            }
            .list{
                padding: 15px 0;
                span{
                    display: inline-block;
                    width: 100%;
                    line-Height: 40px;
                    font-size:14px;
                    color:#d4caca;
                    a{
                        color:#d4caca;
                    }
                    a:hover{
                        color:#7059EF;
                    }
                }
            }
        }
        .logo_show{
            padding:20px;
            text-align:center; 
            .wchart{
                margin-top:20px;
               img{
                   width: 40%;
               }
            }
        }
    }
`

class Header extends Component{
    constructor(props){
        super(props);
        this.state={
            nowYear:new Date().getFullYear()
        }
    }
    componentDidMount(){

    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    render(){
        return <Div>
            <div className="information">
                <div className="inline logo_show">
                    <div>
                        <img style={{height:"60px"}} src={Logo} alt=""/>
                    </div>
                    <div className="wchart">
                        <img src={Wchart} alt=""/> 
                    </div>
                </div>
                <div className="inline" style={{width:"46%"}}>
                    <div className="title">
                        联系方式
                        <b></b>
                    </div>
                    <div className="list">
                        <span>电话：400-029-4789</span>
                        <span>邮箱：suport@seclover.com</span>
                    </div>
                </div>
                <div className="inline" style={{width:"20%"}}>
                    <div className="title">
                        关于我们
                        <b></b>
                    </div>
                    <div className="list">
                        <span>
                            <a href="/#/">洞明</a>
                        </span>
                        <span>
                            <a href={RealmName.tinypark} target="_blank" rel="noopener noreferrer">论坛</a>
                        </span>
                        <span>
                            <a href={RealmName.yunyan} target="_blank" rel="noopener noreferrer">云演</a>
                        </span>
                        <span>
                            <a href={RealmName.yanxing} target="_blank" rel="noopener noreferrer">雁行</a>
                        </span>
                    </div>
                </div>
            </div>
            <div className="copyright">
                版权所有@{this.state.nowYear} 洞明社区 |
                <a target="blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002000989">
                    <img src={Icon} alt=''/>
                    陕公安备61019002000989
                </a> 
                <a target="blank" href="https://beian.miit.gov.cn">
                    陕ICP备14009494号-6
                </a>
            </div>
        </Div>
    }
}
export default Header;
