import React, { Component } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper/dist/js/swiper.js';
import Next from '../../images/next.png';
import Pre from '../../images/pre.png';

const Div=styled.div`
    position: relative;
    .swiper-container{
        .swiper-wrapper{
            cursor: pointer;
            img{
                width: 100%;
            }
        }
       .swiper-button-next{
            opacity: 0;
            overflow:hidden;
       }
       .swiper-button-prev{
            opacity: 0;
            overflow:hidden;
       }
    }
    .button-next{
        position: absolute;
        right: -150px;
        top:257px;
        background:${props=>{
            return "url("+Next+") no-repeat"
        }};
        width: 60px;
        height: 60px;
        cursor: pointer;
    }
    .button-prev{
        position: absolute;
        left: -150px;
        top:257px;
        background:${props=>{
            return "url("+Pre+") no-repeat"
        }};
        width: 60px;
        height: 60px;
        cursor: pointer;
    }
`

class Carousel extends Component{
    constructor(props){
        super(props);
        this.state={
            list:props.list
        }
    }
    componentDidMount(){
        var mySwiper=new Swiper('.swiper-container', {
            loop: true,     //循环
            autoplay: {      //自动播放，注意：直接给autoplay:true的话，在点击之后不能再自动播放了
                delay: 2000,
                disableOnInteraction: false,    //户操作swiper之后，是否禁止autoplay。默认为true：停止。
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,    // 允许点击跳转
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
        //鼠标移上轮播图停止轮播
        mySwiper.el.onmouseover = function(){
            mySwiper.autoplay.stop();
        }
        //鼠标移出轮播图开始轮播
        mySwiper.el.onmouseout = function(){
            mySwiper.autoplay.start();
        }
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handleClick(link){
        if(link!==""){
            window.open(link)
        }
    }
    handelNext(){
        document.getElementsByClassName("swiper-button-next")[0].click();
    }
    handelPre(){
        document.getElementsByClassName("swiper-button-prev")[0].click();
    }
    render(){
        return <Div>
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    {
                        this.state.list.length!==0&&
                        this.state.list.map((val,k)=>{
                            return <div key={k+"key"} className="swiper-slide" 
                                        // onClick={this.handleClick.bind(this,val.link)}
                                        >
                                        <img src={val.path} alt="" />
                                    </div>
                        })
                    }
                </div>
                {/* <div id="dian" className='swiper-pagination'></div> */}
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </div>
            <div className="button-next" onClick={this.handelNext.bind(this)}></div>
            <div className="button-prev" onClick={this.handelPre.bind(this)}></div>
        </Div>
    }
}
export default Carousel;