import React, { Component } from 'react';
import styled from 'styled-components';
import Fetch from '../communal/Fetch';
const Div=styled.div`
    background:#fff;
    padding:15px;
    margin:10px 0;
    .single-filter{
        margin:10px 0;
        .title{
            display: inline-block;
            vertical-align:top; 
            font-size:16px;
            font-weight:bold; 
            line-height:40px; 
        }
        .child-style{
            display: inline-block;
            vertical-align:top; 
            // height: 40px;
            span{
                display: inline-block;
                color: #333;
                vertical-align:middle; 
                padding: 5px 15px;
                border-radius: 18px;
                font-size: 14px;
                margin:0 5px;
                margin-top: 5px;
                cursor: pointer;
            }
            .active{
                background: #7059EF;
                color: #fff;
            }
        }
        .safe{
            width: 1080px;
        }
        .trade{
            width: 800px;
        }
        .search-model{
            float: right;
            margin-right:20px;
            margin-top: 2.5px;
            input{
                border:1px solid #7059EF;
                box-sizing:border-box;
                height: 35px; 
                padding:0 10px;
                width: 200px;
            }
            span{
                background: #7059EF;
                color:#fff;
                display: inline-block;
                line-height:35px;
                vertical-align:middle;  
                cursor: pointer;
                padding:0 10px;
                font-size:14px;
                vertical-align: top;
                margin-top: 0px;
            }
        }
    }
`
class Filtrate extends Component{
    constructor(props){
        super(props);
        this.state={
            safeType:[],
            tradeType:[],
            screen:this.props.screen
        }
    }
    componentDidMount(){
        //安全分类列表
        Fetch.get("/api/products/sec_classifies/").then(data=>{
            data.unshift({
                name:"全部",
                id:"",
                check:true
            })
            this.setState({
                safeType:data
            })
        })
        //行业分类列表
        Fetch.get("/api/products/industry_classifies/").then(data=>{
            data.unshift({
                name:"全部",
                id:"",
                check:true
            })
            this.setState({
                tradeType:data
            })
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handelChange(type,index){
        let safeType=this.state.safeType;
        let tradeType=this.state.tradeType;
        let id="";
        if(type===1){
            for(var i=0;i<safeType.length;i++){
                safeType[i].check=false;
            }
            safeType[index].check=true;
            id=safeType[index].id;
        }else if(type===2){
            for(var j=0;j<tradeType.length;j++){
                tradeType[j].check=false;
            }
            tradeType[index].check=true;
            id=tradeType[index].id;
        }
        this.setState({
            safeType:safeType,
            tradeType:tradeType
        })
        this.props.setScreen(type,id)
    }
    handelChnage(event){
        let value=event.target.value;
        this.props.change(value);
    }
    render(){
        const safeType=this.state.safeType;
        const tradeType=this.state.tradeType;
        return <Div>
            <div className="single-filter">
                <span className="title">安全分类：</span>
                <div className="child-style safe">
                    {
                        safeType.map((val,key)=>{
                            return <span key={"key"+key} 
                                className={val.check?"active":""}
                                onClick={this.handelChange.bind(this,1,key)}
                            >
                                {val.name}
                            </span>
                        })
                    }
                </div>
            </div>
            <div className="single-filter">
                <span className="title">行业分类：</span>
                <div className="child-style trade">
                    {
                        tradeType.map((val,key)=>{
                            return <span key={"key"+key}
                                className={val.check?"active":""}
                                onClick={this.handelChange.bind(this,2,key)}
                            >
                                {val.name}
                            </span>
                        })
                    }
                </div>
                <div className="search-model">
                    <input type="text" 
                        placeholder="请输入产品名称" 
                        onChange={this.handelChnage.bind(this)}
                        value={this.state.screen.search}
                    />
                    <span>搜索</span>
                </div>
            </div>
        </Div>
    }
}
export default Filtrate;