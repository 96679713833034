import React, { Component } from 'react';
import '../css/App.css';
import {setPathname,setLogin,setUuid,setInfo,setShow} from './redux/Actions';
import store from './redux/Store';
import {
	Router,
	Route,
	Switch
} from 'react-router-dom';
import history from './communal/history';
import Home from './home';
import EcoDeck from './EcoDeck';
import SafetyMatrix from './SafetyMatrix';
import Tools from './Communitytools';
import Shop from './shop';
import Personal from './personalCenter';
import Header from './header';
import Footer from './footer';
import '../css/font-awesome.min.css';
import 'swiper/dist/css/swiper.min.css';
import Fetch from './communal/Fetch';
import {connect} from "react-redux";
import $ from 'jquery';
import realmName from '../json/realmName.json';

class App extends Component{
	constructor(props){
		super(props);
		this.state={
			
		}
	}
	refresh() {
		store.dispatch(setPathname(history.location.pathname));
		if(history.location.pathname.indexOf("/matrix/apply") !== -1){
			store.dispatch(setShow(false));
		}else{
			store.dispatch(setShow(true));
		}
    }
	bind_uri_refresh() {
        window.addEventListener('load', this.refresh.bind(this), false);
        window.addEventListener('hashchange', this.refresh.bind(this), false);
    }
	componentDidMount(){
		this.loginStatus();
		this.bind_uri_refresh();
		document.getElementsByClassName("App-content")[0].style.minHeight=document.body.clientHeight - 386+"px";
	}
	//退出洞明
	handleLogoutYun(){
		// var that = this;
		$.ajax({
			url: "/api/user/logout/",
			type: "GET",
			success: function (data) {
				window.location.reload();
			}
		})
	}
	// 退出SSO
	handleLogout() {
		var that = this;
		this.handleLogoutYun()
		$.ajax({
			url: realmName.sso + "/server_app/logout/",
			type: "GET",
			data: { uuid: that.props.uuid },
			// dataType: "jsonp",    //跨域json请求一定是jsonp
			// jsonp: "callbackparam",    //跨域请求的参数名，默认是callback
			success: function (data) {

			}
		})
	}
	loginStatus(){
		Fetch.get("/api/user/status/").then(data=>{
			let login=false
			if(data.status){
				login=true;
				Fetch.get("/api/user/uuid/").then(res=>{
					if(res.status===1){
						store.dispatch(setUuid(res.info));
					}
					let that=this;
					$.ajax({
						url: realmName.sso + "/server_app/custom/is_login/",
						type: "get",
						data: { user_uuid:res.info },
						success: function (info) {
							if(info.code===2){
								that.handleLogout()
							}
						}
					})
				})
				Fetch.get("/api/user/info/").then(res=>{
					store.dispatch(setInfo(res));
				})
			}
			store.dispatch(setLogin(login));
		})
	}
	componentWillUnmount(){
		this.setState=(state,callback)=>{
			return
		}
	}
	render(){
		return (
			<div className="App">
				<div className="App-content">
					<Router history={history}>
						{
							this.props.isShow&&
							<Header />
						}
						<div className="global-content">
							<Switch>
								<Route exact path="/" component={()=>{
									return <Home />  //首页
								}}/>
								<Route path="/ecology" component={()=>{
									return <EcoDeck />  //社区生态
								}}/>
								<Route path="/matrix" component={()=>{
									return <SafetyMatrix />  //安全矩阵
								}}/>
								<Route path="/tools" component={()=>{
									return <Tools />  //社区工具
								}}/>
								<Route path="/shop" component={()=>{
									return <Shop />  //商城
								}}/>
								<Route path="/personal" component={()=>{
									return <Personal />  //个人中心
								}}/>
							</Switch>
						</div>
					</Router>
				</div>
				{
					this.props.isShow&&
					<Footer />
				}
			</div>
		);
	}
}
const mapStateToProps = (state)=> {
    return {
       isShow:state.show
    }
};
export default connect(
   mapStateToProps,
)(App);
