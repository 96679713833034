

const initialState = {
    pathname:"/",
    login:false,
    uuid:"",
    info:{
      signature:"",
      realname:"",
      email:""
    },
    show:true
}
export default function(state=initialState, action) {
    switch (action.type) {
      case "SET_NAME": {
        return {
          ...state,
          pathname:action.pathname
        }
      }
      case "SET_LOGIN": {
        return {
          ...state,
          login:action.login
        }
      }
      case "SET_UUID": {
        return {
          ...state,
          uuid:action.uuid
        }
      }
      case "SET_INFO": {
        return {
          ...state,
          info:action.info
        }
      }
      case "SET_SHOW": {
        return {
          ...state,
          show:action.show
        }
      }
      default:
        return state;
    }
}