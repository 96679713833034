import React, { Component } from 'react';
import styled from 'styled-components';
import Page from '../communal/Page';
import Fetch from '../communal/Fetch';

const Div=styled.div`
    .single-tool{
        width: 23%;
        margin-right:2%;
        margin-bottom:20px;
        box-shadow: 0px 2px 0px rgba(170,170,170,0.1);
        background: #fff;
        margin-bottom: 20px;
        border-radius: 3px;
        display: inline-block;
        .name{
            a{
                overflow: hidden;
                margin-bottom: 7px;
                display: block;
                cursor: pointer;
                padding: 10px 18px 0;
                color: #333;
                img{
                    width: 32px;
                    height: 32px;
                    border-radius: 32px;
                    float: left;
                    display: block;
                }
                span{
                    display: block;
                    padding-left: 40px;
                    margin-top: 6px;
                    font-weight: 700;
                    font-size: 15px;
                    color: inherit;
                }
            }
            a:hover{
                color:#7059EF;
            }
        }
        .tool-describe{
            color: #666666;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 2px;
            padding: 0 18px;
        }
        .stat{
            padding: 0 18px 8px;
            .page-view{
                font-size: 12px;
                color: #bbb;
                float: left;
            }
            .like{
                cursor: pointer;
                float: right;
                font-size: 12px;
                color: #bbb;
            }
            span{
                i{
                    vertical-align:middle;
                    margin-top:-3px;
                    margin-right:5px;
                    font-size:16px; 
                }
            }
        }
    }
`
class List extends Component{
    constructor(props){
        super(props);
        this.state={
            list:[],
            filter:{
                page:1,
                size:12,
            },
            allCount:0
        }
    }
    componentDidMount(){
        this.getProduct();
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    getProduct(){
        let screen=this.props.screen;
        let filter=this.state.filter;
        let str="?page="+filter.page+"&size="+filter.size;
        if(screen.res_id!==""){
            str+="&res_id="+screen.res_id
        }
        if(screen.sec_id!==""){
            str+="&sec_id="+screen.sec_id
        }
        if(screen.search!==""){
            str+="&search="+screen.search
        }
        Fetch.get("/api/tools/"+str).then(data=>{
            this.setState({
                allCount:data.count,
                list:data.results
            })
        })
    }
    getPages(page){
        let filter=this.state.filter;
        filter.page=page;
        this.setState({
            filter:filter
        },()=>{
            this.getProduct()
        })
    }
    handelClick(id,index,type){
        let list=this.state.list;
        let action="";
        if(type===1){
            action="click"
        }else if(type===2){
            action="like"
        }
        Fetch.put("/api/tools/"+id+"/",{
            body:JSON.stringify({action:action}),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            if(type===1){
                list[index].page_view++
            }else if(type===2){
                list[index].likes++
            }
            this.setState({
                list:list
            })
        })
    }
    render(){
        const list=this.state.list;
        return <Div>
            {   
                list.map((val,key)=>{
                    return <div
                        key={"key"+key}
                        className="single-tool"
                        style={((key+1)%4===0)?{marginRight:"0"}:{marginRight:"2.65%"}}
                    >  
                        <div className="name">
                            <a href={val.link} onClick={this.handelClick.bind(this,val.id,key,1)} target="_blank" rel="noopener noreferrer">
                                <img src={val.logo} alt=""/>
                                <span>{val.name}</span>
                            </a>
                        </div>
                        <div className="tool-describe">
                            {val.desc}
                        </div>
                        <div className="stat">
                            <span className="page-view">
                                <i className="fa fa-eye" aria-hidden="true"></i>
                                {val.page_view}
                            </span>
                            <span className="like" onClick={this.handelClick.bind(this,val.id,key,2)}>
                                <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                {val.likes}
                            </span>
                            <div style={{clear:"both"}}></div>
                        </div>
                    </div>
                }) 
            }
            {
                list.length===0&&
                <div className="not-data">暂未筛选到数据</div>
            }
            <Page
                key="page"
                upDown
                pageNumber
                allNumber
                // jumpNumber
                // selectNumber
                nowpage={this.state.filter.page} //当前页
                allPage={this.state.allCount}
                everyPage={this.state.filter.size}
                onClick={this.getPages.bind(this)}
                onChange={this.getPages.bind(this)}
            />
        </Div>
    }
}
export default List;